import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
    return `${config["entender-api"].url}${path}`;
};

export const getUserById = async (userId) => {
    const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
    };

    const response = await fetch(getUrl("/user/" + userId), requestOptions);

    return response;
};

export const createStudentUser = async ({ username }) => {
    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
        body: JSON.stringify({ username: username }),
    };

    const response = await fetch(getUrl("/createStudentUser"), requestOptions);

    return response;
}

export const deleteUserById = async (userId) => {

    const requestOptions = {
        method: 'DELETE',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
    };

    const response = await fetch(getUrl("/user/" + userId), requestOptions);

    return response;
};