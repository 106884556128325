import config from "../config/default.json";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Button = styled.div`
    font-family: sassoon-sans-std-bold;
    border-radius: 30px;
    background: transparent;
    padding: .7em .8em;
    color: #fff;
    font-weight: bold;
    outline: none;
    border: 1px solid #fff;
    cursor: pointer;
    transition: all .3s ease-in-out;
    text-decoration: none;
    margin-left: 15px;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: #309E72;
        color: white;
        border: 1px solid #309E72;
    }
`;

const ButtonRes = styled.div`
    color: #000;
    font-family: sassoon-sans-std-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &:hover {
        color: #06bb70;
    }
`;

export const LogOff = ({CookieKeys}) => {
    let navigate = useNavigate();

    const logOut = async () => {
        CookieKeys.map((cookie) => {
            cookies.remove(cookie, {path: "/", domain: `${config["entender-api"].domain}`});
    });

        navigate("/",{ replace: true });
    }

    return (
        <Button onClick={logOut}>Cerrar sesión</Button>
    );
};

export const LogOffRes = ({CookieKeys}) => {
    let navigate = useNavigate();

    const logOut = async () => {
        CookieKeys.map((cookie) => {
            cookies.remove(cookie, {path: "/", domain: `${config["entender-api"].domain}`});
    });

        navigate("/",{ replace: true });
    }

    return (
        <ButtonRes onClick={logOut}>Cerrar sesión</ButtonRes>
    );
};