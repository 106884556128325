// ----------------------------------- Librarys -----------------------------------
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Semesters } from "../../assets/semesters";
import { Genres } from "../../assets/genres";
import getNeurodiversities from "../../controllers/neurodiversities";
import {
    getInstitutions,
    getCareers,
} from "../../controllers/institutionsAndCareers";
import { addMediaFile, getStudentById, updateStudent } from "../../controllers/students";
import Cookies from "universal-cookie";
// ------------------------------------ Styles ------------------------------------
import {
    RegisterInput,
    GreenButton,
    RegisterBoxRender,
    RegisterCard,
    InputLabel,
    InputLabelMini,
    InputLabelMiniBold,
    RegisterSelector,
    AboutMe,
    SearchBar,
    AddSkill,
    LabelRed,
    GreenLabel,
    AddNeuro,
} from "../Login/login-registerElements";
import { Toast } from "../alert";
import { Loading } from "../loading";
import Swal from "sweetalert2";
import { getSkills, addSkill } from "../../controllers/skills";
// --------------------------------------------------------------------------------

const cookies = new Cookies();

//import countries ... algo

function SelectorOptions({ options }) {
    return options.map((option) => (
        <option key={option.id} value={option.value}>
            {option.name}
        </option>
    ));
}

function SelectorOptionsInstitutions({ options }) {
    return options.map((option) => (
        <option key={option.id} value={option.id}>
            {option.description}
        </option>
    ));
}

function SelectorOptionsCareers({ options, institutionId }) {
    const arrayAux = [];

    options.forEach((option) => {
        if (option.institutionId === institutionId) {
            arrayAux.push(option);
        }
    });

    options = arrayAux;

    return options.map((option) => (
        <option key={option.id} value={option.id}>
            {option.name}
        </option>
    ));
}

function SearchBarSkills (props) {
    let SkillsList = props.SkillsList;
    let stateF = props.stateF;
    let setStateF = props.setStateF;
    let skillsAdded = stateF.skillsAdded;

    function SelectorSkills({ options }) {
        return options.map((option) => (
            <div className="col-auto">
                <GreenLabel
                    id={option + "Id"}
                    value={option}
                    className="btn btn-primary"
                >
                    {option}&emsp;
                    <LabelRed onClick={() => deleteSkill(option)}>X</LabelRed>
                </GreenLabel>
            </div>
        ));
    }

    const [state, setState] = useState({
        skill: "",
        getSkillsDataBool: false,
    });

    function formChange(e) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, skill: e.target.value });
    }

    function addSkill() {
        if (!skillsAdded.find((skill) => skill === state.skill)) {
            if (state.skill !== "") {
                skillsAdded.push(state.skill);
                setStateF({ ...stateF, skillsAdded: skillsAdded });
            }
        }
    }

    function deleteSkill(option) {
        skillsAdded = skillsAdded.filter((skill) => skill !== option);
        setStateF({ ...stateF, skillsAdded: skillsAdded });
    }

    const render = () => {
        return (
            <div className="searchBar">
                <div className="searchInputs">
                    <SearchBar 
                        id={props.id}
                        className="mdb-select md-form"
                        searchable="Busca tus habilidades..."
                        value={state.skill}
                        onChange={(event) => formChange(event)}
                    >
                        <option value="" disabled selected>
                            Busca tus habilidades...
                        </option>
                        <SelectorOptions options={SkillsList} />
                    </SearchBar>
                    <AddSkill
                        type="button"
                        className="btn btn-primary"
                        onClick={() => addSkill()}
                    >
                        +
                    </AddSkill>
                </div>
                <br />
                <div class="row">
                    <SelectorSkills options={skillsAdded} />
                </div>
            </div>
        );
    };

    return render();
}

function SearchBarNeurodiversities({
    NeurodiversitiesList,
    stateF,
    setStateF,
}) {
    let neurosAdded = stateF.neurosAdded;

    function SelectorNeuros({ options }) {
        return options.map((option) => (
            <div className="col-auto">
                <GreenLabel
                    id={option + "Id"}
                    value={option}
                    className="btn btn-primary"
                >
                    {option}&emsp;
                    <LabelRed onClick={() => deleteNeuro(option)}>X</LabelRed>
                </GreenLabel>
            </div>
        ));
    }

    const [state, setState] = useState({
        neuro: "",
        getNeurosDataBool: false,
    });

    function formChange(e) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, neuro: e.target.value });
    }

    function addNeuro() {
        if (!neurosAdded.find((neuro) => neuro === state.neuro)) {
            if (state.neuro !== "") {
                neurosAdded.push(state.neuro);
                setStateF({ ...stateF, neurosAdded: neurosAdded });
            }
        }
    }

    function deleteNeuro(option) {
        neurosAdded = neurosAdded.filter((neuro) => neuro !== option);
        setStateF({ ...stateF, neurosAdded: neurosAdded });
    }

    const render = () => {
        return (
            <div className="searchBar">
                <div className="searchInputs">
                    <SearchBar
                        className="mdb-select md-form"
                        style={{ boxShadow: "none" }}
                        searchable="Busca las neurodiversidades..."
                        value={state.neuro}
                        onChange={(event) => formChange(event)}
                    >
                        <option value="" disabled selected>
                            Busca las neurodiversidades...
                        </option>
                        <SelectorOptions options={NeurodiversitiesList} />
                    </SearchBar>
                    <AddNeuro
                        type="button"
                        className="btn btn-primary"
                        style={{ marginLeft: "1em" }}
                        onClick={() => addNeuro()}
                    >
                        +
                    </AddNeuro>{" "}
                </div>{" "}
                <br />
                <div class="row">
                    <SelectorNeuros options={neurosAdded} />
                </div>
            </div>
        );
    };

    return render();
}

function StudentRegisterBox() {
    const studentId = cookies.get("studentId");
    const userId = cookies.get("userId");

    const [isLoading, setLoading] = useState(true);
    const [verifyPassword, setVerifyPassword] = useState(false);

    const [state, setState] = useState({
        institutions: [],
        careers: [],
        skills: [],
        neuros: [],
        skillsAdded: [],
        neurosAdded: [],
        password: "",
        repeatPassword: "",
    });

    const [studentData, setStudentData] = useState({
        studentId: studentId,
        userId: userId,
        password: "",
        givenNames: "",
        familyName1: "",
        familyName2: "",
        familyNames: "",
        dateOfBirth: "",
        genre: "M",
        //citizenship: "",
        institutionId: "",
        careerId: "",
        currentGrade: "6",
        studentCode: "",
        skills: "",
        neurodiversities: "",
        email: "",
        phoneNumber: "",
        selfDescription: "",
    });

    const getStudentData = async () => {
        const response = await getStudentById(studentId);
        const content = await response.json();
        setStudentData({ ...studentData, email: content.email });

        const institutionData = await getInstitutions();
        const careerData = await getCareers();

        const skillsData = await getSkills();
        const neurosData = await getNeurodiversities();

        setState({
            ...state,
            institutions: institutionData,
            careers: careerData,
            skills: skillsData,
            neuros: neurosData,
        });
    };

    const addNewSkill = async () => {
        Swal.fire({
            title: 'Nueva habilidad',
            html: `<input type="text" id="name" class="swal2-input" style="width: 80%" placeholder="Nombre">
            <textarea type="text" id="description" class="swal2-textarea" style="width: 80%" placeholder="Descripción">`,
            confirmButtonText: 'Agregar',
            focusConfirm: false,
            preConfirm: () => {
                const name = Swal.getPopup().querySelector('#name').value;
                const description = Swal.getPopup().querySelector('#description').value;
                if (!name) {
                    Swal.showValidationMessage(`Llena por lo menos el nombre`);
                }
                return { name: name, description: description }
            }
        }).then( async (result) => {
            let name = result.value.name;
            let description = result.value.description;
            let exists = false;
            
            const select = document.getElementById('SearchBarSkills');

            for(let i=0; i<select.options.length; i++){
                if(select.options[i].innerHTML == name){
                    exists = true;
                }
            }

            if(exists){
                Toast.fire({
                    icon: "error",
                    title: "Esta habilidad ya existe", // ? Regresar otro mensaje
                });
            }
            else{
                const response = await addSkill({ name, description });

                if (response.ok) {

                    var opt = document.createElement('option');
                    opt.value = name;
                    opt.innerHTML = name;

                    select.appendChild(opt);

                    Toast.fire({
                        icon: "success",
                        title: "Habilidad agregada!",
                    });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Error", // ? Regresar otro mensaje
                    });
                }
            }
        })
    };

    // Missing implementation
    const addImageProfile = async () => {
        const fileInput = document.querySelector('#student_picture_file');

        const formData = new FormData();
        formData.append('file', fileInput.files[0]);

        const response = await addMediaFile(formData, studentId);

        if (response.ok) {
            Toast.fire({
                icon: "success",
                title: "Imagen agregada!",
            });
        } else {
            Toast.fire({
                icon: "error",
                title: "Error", // ? Regresar otro mensaje
            });
        }
    }

    const verifyPasswords = async (event, field) => {

        let password = state.password;
        let repeatPassword = state.repeatPassword;

        const input = document.getElementById("repetPasswordInput");

        if(field == "pass"){
            password = event.target.value;
            setState({...state, password: password});
        }
        if(field == "repeatPass"){
            repeatPassword = event.target.value;
            setState({...state, repeatPassword: repeatPassword});
        }

        if(password == repeatPassword){
            input.setAttribute("style", "border: solid 1px #28a745;");

            setVerifyPassword(true);

            setStudentData({...studentData, password: state.password});
        }
        else {
            input.setAttribute("style", "border: solid 1px #d33;");

            setVerifyPassword(false);
        }
    }

    let navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(verifyPassword)
        {
            studentData.skills = state.skillsAdded.toString();
            studentData.neurodiversities = state.neurosAdded.toString();

            if(studentData.skills != "" && studentData.neurodiversities != "")
            {
                const response = await updateStudent(studentData);
    
                if (response.ok) {
                    Toast.fire({
                        icon: "success",
                        title: "Registro exitoso!",
                    });
        
                    state.institutions.forEach((element) => {
                        if (parseInt(studentData.institutionId) === element.id) {
                            cookies.set("institutionName", element.description, { path: "/" });
                            return;
                        }
                    });
        
                    state.careers.forEach((element) => {
                        if (parseInt(studentData.careerId) === element.id) {
                            cookies.set("careerName", element.name, { path: "/" });
                            return;
                        }
                    });
        
                    navigate("/",{ replace: true });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Error", // ? Regresar otro mensaje
                    });
                }
            }
            else if(studentData.skills == "")
            {
                Toast.fire({
                    icon: "error",
                    title: "Agrega por lo menos una habilidad", // ? Regresar otro mensaje
                });
            }else if(studentData.neurodiversities == "")
            {
                Toast.fire({
                    icon: "error",
                    title: "Agrega por lo menos una condición en la sección de Neurodiversidades", // ? Regresar otro mensaje
                });
            }
            
        } else {
            Toast.fire({
                icon: "error",
                title: "Contraseñas incorrectas", // ? Regresar otro mensaje
            });
        }
    };

    useEffect(() => {
        if (studentData.email === "") getStudentData();
        else {
            setLoading(false);
        }
    });

    const render = () => {
        if (isLoading) return <Loading />;

        return (
            <RegisterBoxRender className="container">
                <RegisterCard className="card">
                    <div className="card-body">
                        <form class="was-validated" onSubmit={handleSubmit}>
                            {/* <div className="row ">
                                <div className="col-12">
                                    <InputLabel className="form-label">Foto de perfil</InputLabel><br/>
                                    {/* Agregar la imagen por default }
                                    <input id="student_picture_file" type="file" required></input><br/><br/>
                                    <GreenButton
                                        
                                        type="button"
                                        className="btn btn-primary"
                                        style={{ fontSize: "1em" }}
                                        onClick={addImageProfile}
                                    >
                                        Agregar foto de perfil
                                    </GreenButton>
                                    <br />
                                    <br />
                                </div>
                            </div> */}
                            <div className="row ">
                                <div className="col-12">
                                    <InputLabel className="form-label">Nombre(s)</InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        placeholder="Ingresa tu(s) nombre(s)"
                                        type="text"
                                        value={studentData.givenNames}
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                givenNames: e.target.value,
                                            })
                                        }
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-6">
                                    <InputLabel className="form-label">
                                        Primer Apellido
                                    </InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        placeholder="Ingresa tu primer apellido"
                                        type="text"
                                        value={studentData.familyName1}
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                familyName1: e.target.value,
                                            })
                                        }
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                </div>
                                <div className="col-6 col-xs-6">
                                    <InputLabel className="form-label">
                                        Segundo Apellido
                                    </InputLabel>
                                    <br />
                                    <RegisterInput
                                        placeholder="Ingresa tu segundo apellido"
                                        type="text"
                                        value={studentData.familyName2}
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                familyName2: e.target.value,
                                            })
                                        }
                                    />
                                    <br />
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-6">
                                    <InputLabel className="form-label">
                                        Fecha de Nacimiento
                                    </InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        type="date"
                                        value={studentData.dateOfBirth}
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                dateOfBirth: e.target.value,
                                            })
                                        }
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                </div>
                                <div className="col-6">
                                    <InputLabel className="form-label">Género</InputLabel>
                                    <br />
                                    <RegisterSelector
                                        class="form-select"
                                        value={studentData.genre}
                                        onChange={(e) =>
                                            setStudentData({ ...studentData, genre: e.target.value })
                                        }
                                    >
                                        <SelectorOptions options={Genres} />
                                    </RegisterSelector>
                                    <br />
                                </div>
                            </div>
                            {/* 
                                <div className="row">
                                    <div className="col-12">
                                    <InputLabel  className="form-label">Nacionalidad</InputLabel>
                                    <RegisterSelector className="form-select">
                                        <SelectorOptions options={Paises}/> 
                                    </RegisterSelector>
                                    <br/>
                                    <br/>
                                </div>
                            */}
                            <div className="row">
                                <div className="col-12">
                                    <InputLabel className="form-label">
                                        Centro Universitario
                                    </InputLabel>
                                    <RegisterSelector
                                        class="form-select"
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                institutionId: e.target.value,
                                            })
                                        }
                                        style={{ textAlign: "center" }}
                                        required
                                    >
                                        <option value="" disabled selected>
                                            Selecciona tu centro universitario
                                        </option>
                                        <SelectorOptionsInstitutions
                                            style={{ textAlign: "left" }}
                                            options={state.institutions}
                                        />
                                    </RegisterSelector>
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                </div>
                            </div>
                            {studentData.institutionId !== "" ? (
                                <div className="row">
                                    <div className="col-12">
                                        <InputLabel className="form-label">Carrera</InputLabel>
                                        <RegisterSelector
                                            class="form-select"
                                            onChange={(e) =>
                                                setStudentData({
                                                    ...studentData,
                                                    careerId: e.target.value,
                                                })
                                            }
                                        >
                                            <SelectorOptionsCareers
                                                options={state.careers}
                                                institutionId={parseInt(studentData.institutionId)}
                                            />
                                        </RegisterSelector>
                                        <br />
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div className="row">
                                <div className="col-12">
                                    <InputLabel className="form-label">
                                        Semestre Actual
                                    </InputLabel>
                                    <RegisterSelector
                                        class="form-select"
                                        value={studentData.currentGrade}
                                        required
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                currentGrade: e.target.value,
                                            })
                                        }
                                    >
                                        <SelectorOptions options={Semesters} />
                                    </RegisterSelector>
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <InputLabel className="form-label">
                                        Código de estudiante
                                    </InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        placeholder="Ingresa tu código de estudiante"
                                        type="text"
                                        value={studentData.studentCode}
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                studentCode: e.target.value,
                                            })
                                        }
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <InputLabel class="form-label">Habilidades</InputLabel><br/>
                            <InputLabelMini style={{fontFamily: "sassoon-sans-std"}}>Selecciona una habilidad de la lista y pulsa el botón <b>+</b></InputLabelMini>
                            <div class="row">
                                <SearchBarSkills
                                    id="SearchBarSkills"
                                    SkillsList={state.skills}
                                    stateF={state}
                                    setStateF={setState}
                                />
                            </div>
                            <br />
                            <div className="row justify-content-center">
                                <div className="col-12" style={{ textAlign: "center" }}>
                                    <br />
                                    <GreenButton
                                        type="button"
                                        className="btn btn-primary"
                                        style={{ fontSize: "1em" }}
                                        onClick={addNewSkill}
                                    >
                                        Agregar nueva habilidad
                                    </GreenButton>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <InputLabel class="form-label">Neurodiversidades</InputLabel><br/>
                            <InputLabelMiniBold style={{fontFamily: "sassoon-sans-std"}}>Selecciona una condición de la lista y pulsa el botón +</InputLabelMiniBold>
                            <div class="row">
                                <SearchBarNeurodiversities
                                    NeurodiversitiesList={state.neuros}
                                    stateF={state}
                                    setStateF={setState}
                                />
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-12">
                                    <br />
                                    <InputLabel className="form-label">
                                        Correo electrónico
                                    </InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        placeholder="Ingresa tu correo electrónico"
                                        type="email"
                                        value={studentData.email}
                                        onChange={(e) =>
                                            setStudentData({ ...studentData, email: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                    <br />
                                </div>

                                <div className="col-12">
                                    <br />
                                    <InputLabel className="form-label">
                                        Nueva contraseña
                                    </InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        placeholder="Ingresa tu contraseña"
                                        type="password"
                                        value={state.password}
                                        onChange={(e) => verifyPasswords(e, "pass")}
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                </div>

                                <div className="col-12">
                                    <br />
                                    <InputLabel className="form-label">
                                        Repite la contraseña
                                    </InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        id="repetPasswordInput"
                                        placeholder="Repite tu contraseña"
                                        type="password"
                                        value={state.repeatPassword}
                                        onChange={(e) => verifyPasswords(e, "repeatPass")}
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                    <br />
                                </div>

                                <div className="col-12">
                                    <InputLabel className="form-label">Teléfono</InputLabel>
                                    <br />
                                    <RegisterInput
                                        required
                                        placeholder="Ingresa tu número de teléfono"
                                        type="number"
                                        value={studentData.phoneNumber}
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                phoneNumber: e.target.value,
                                            })
                                        }
                                    />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <InputLabel className="form-label">
                                        Cuéntanos sobre ti
                                    </InputLabel>
                                    <InputLabelMiniBold className="form-label">
                                        (Opcional)
                                    </InputLabelMiniBold>
                                    <br />
                                    <AboutMe
                                        placeholder="Cuéntanos acerca de ti..."
                                        maxLength={250}
                                        value={studentData.selfDescription}
                                        onChange={(e) =>
                                            setStudentData({
                                                ...studentData,
                                                selfDescription: e.target.value,
                                            })
                                        }
                                    />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <InputLabel className="form-label">
                                    Aviso de Privacidad
                                </InputLabel>
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="validationFormCheck1"
                                        required
                                        style={{ float: "none", marginRight: "1em" }}
                                    />
                                    <label class="form-check-label" for="invalidCheck">
                                        He leído y acepto el{" "}
                                        <a href="http://www.transparencia.udg.mx/aviso-confidencialidad" target="_blank">
                                            Aviso de privacidad
                                        </a>
                                    </label>
                                    <br />
                                    <br />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-12" style={{ textAlign: "center" }}>
                                    <br />
                                    <GreenButton type="submit" className="btn btn-primary">
                                        Registrarme
                                    </GreenButton>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </form>
                    </div>
                </RegisterCard>
            </RegisterBoxRender>
        );
    };

    return render();
}

export default StudentRegisterBox;
