import React, {  useState, useEffect } from "react";
import Navbar from '../employerNavbar';
import {ListTest, JobRender, ButtonNav, DivContainer, ProfileImg, ProfileImgContainer, ProfileImgBorder, UsernameLabel, StudentData, StudentDataCard, CardTitle,ProfileView,TasksHeader,Task,CardHeader, ListItem} from './hhrrElements';
import MyOffers from './myOffers';
import ApplicationItems from './applications';
import OfferBox from './offerBox';
import { Loading } from '../loading';
import getNeurodiversities from "../../controllers/neurodiversities";

import { getMyApplications } from '../../controllers/employerUser';
import { getStudents } from '../../controllers/studentInfo';
import { getInstitutions } from "../../controllers/institutionsAndCareers";

import Cookies from 'universal-cookie';
import { getSkills } from "../../controllers/skills";
import OfferBoxBlank from "./offerBoxBlank";
import { getJobOfferByEmployerId } from "../../controllers/jobOffers";

const cookies = new Cookies();

const initialState = {
    getAllDataBool: false,
}

function EmployerProfile() {

    const [isLoading, setLoading] = useState(true);
    const [employerDataCharged, setEmployerDataCharged] = useState(false);
    
    const [state, setState] = useState({
        neuros: [],
        myOffers : [],
        applications: [],
        students: [],
        institutions: [],
        skills: [],
        focused: true,
        ...initialState,
    });
    
    //Gets neurodiversity ands skills list
    const getAllData = async () => {
        const neuroData = await getNeurodiversities();
        const institutionData = await getInstitutions();
        const skillsData = await getSkills();
        const offers = await getJobOfferByEmployerId(cookies.get("employerId"));

        setState({...state, neuros: neuroData, institutions: institutionData, skills: skillsData, myOffers: offers});

        setEmployerDataCharged(true);
    }

    //Loads data for applications' tab 

    const getApplications = async() => { // ? No se usa
        const applications = await getMyApplications(cookies.get("employerId"));
        const students = await getStudents();

        setState({...state, applications : applications, students: students});
    };

    useEffect(() => {
        if(!employerDataCharged)
            getAllData();

        if(employerDataCharged) 
            setLoading(false);
    });

    const ChangeColor = async (button, tab1, tab2) => {

        document.getElementById(button).setAttribute("active","true");
        document.getElementById(button).setAttribute("style","background: white; color: black; margin-bottom: 0px;");
        document.getElementById(tab1).setAttribute("style","background: #309E72; color: white; margin-bottom: 0px;");
        document.getElementById(tab1).setAttribute("active","false");
        document.getElementById(tab2).setAttribute("style","background: #309E72; color: white; margin-bottom: 0px;");
        document.getElementById(tab2).setAttribute("active","false");

        if(button === "applications-tab") getApplications();
    };

    const render = () => {
        if (isLoading) return <Loading/>;

        return (
            <div >
            <Navbar/>
            <ProfileView>
                    <div class="row justify-content-center" style={{height: "92vh"}}>
                        <div class="col-md-5" style={{height: '100%', padding: 0}}>
                            <JobRender>
                                <DivContainer class="card w-100">
                                    <CardHeader class="card-header">
                                        
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <ProfileImgBorder>
                                                    <ProfileImgContainer>
                                                        <ProfileImg src={require('../../assets/images/'+cookies.get("employerLogo")+'.png')}/>
                                                    </ProfileImgContainer>
                                                </ProfileImgBorder>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <div class="row justify-content-center">
                                        <UsernameLabel>
                                            {cookies.get("employerName")}
                                        </UsernameLabel>
                                    </div>
                                    <ListTest>
                                        <StudentData>
                                            <StudentDataCard class="card">
                                                <CardTitle class="card-title">Datos</CardTitle>
                                                <label>Descripción: {cookies.get("description")}</label>
                                                <br/>  
                                                <label>Correo: {cookies.get("email")}</label>
                                            </StudentDataCard>
                                        </StudentData>
                                    </ListTest>
                                </DivContainer>
                            </JobRender>
                        </div>
                        <div class="col-md-7" style={{height: "100%", padding: 0}}>
                            <JobRender>
                        
                                <DivContainer class="card w-100">

                                    <TasksHeader>
                                        
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist" style={{paddingTop: "1em", paddingInline:"1em", borderBottom: "0px", marginBottom: "0px"}}>
                                            <ButtonNav className="nav-link" id="offer-tab" data-bs-toggle="tab" data-bs-target="#offer-task" type="button" role="tab" active="true" style={{background: "white", color: "black", marginBottom: "0px"}} onClick={(e) => ChangeColor(e.target.id, "myOffers-tab", "applications-tab")}>Crear Oferta</ButtonNav>
                                            <ButtonNav className="nav-link" id="myOffers-tab" data-bs-toggle="tab" data-bs-target="#myOffers-task" type="button" role="tab" style={{background: "#309E72", color: "white", marginBottom: "0px"}} onClick={(e) => ChangeColor(e.target.id, "offer-tab", "applications-tab")}>Mis Ofertas</ButtonNav>
                                            <ButtonNav className="nav-link" id="applications-tab" data-bs-toggle="tab" data-bs-target="#applications-task" type="button" role="tab" style={{background: "#309E72", color: "white", marginBottom: "0px"}} onClick={(e) => ChangeColor(e.target.id, "offer-tab", "myOffers-tab")}>Postulaciones</ButtonNav>
                                        </div>

                                    </TasksHeader>

                                    <div style={{height: "89%", marginTop: "1em"}}>
                                        <Task class="card-body">
                                            <div class="tab-content" id="nav-tabContent" style={{height: "100%"}}>

                                                    <div class="tab-pane fade show active" id="offer-task" role="tabpanel" style={{height: "100%"}}>
                                                            <OfferBoxBlank InstitutionsList={state.institutions} SkillsList={state.skills} NeurodiversitiesList={state.neuros}/>
                                                    </div>
                                            
                                                    <div class="tab-pane fade" id="myOffers-task" role="tabpanel" style={{height: "100%"}}>
                                                            <MyOffers InstitutionsList={state.institutions} SkillsList={state.skills} NeurodiversitiesList={state.neuros} />
                                                    </div>

                                                    <div class="tab-pane fade" id="applications-task" role="tabpanel" style={{height: "100%"}}>
                                                            <ApplicationItems Offers={state.myOffers} Applications={state.applications} Students={state.students} Institutions={state.institutions}/>
                                                    </div>
                                            </div>
                                        </Task>
                                    </div>
                                    
                                </DivContainer>

                            </JobRender>
                        </div>
                        
                    </div>
                    </ProfileView>
            </div>
        );
    };

    return render();
};

export default EmployerProfile;