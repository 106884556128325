import React, {  useState, useEffect } from "react";
import styled from "styled-components";
import { Schedule } from "../../assets/schedule";
import { JobType } from "../../assets/jobType";
import {RegisterSelector, RegisterCard, SubmitButton, RegisterInput, OfferDescription, InputLabel} from './hhrrElements';
import Cookies from 'universal-cookie';
import {GreenInputActive} from './hhrrElements';
import { editOffer } from "../../controllers/employerUser";
import { Loading } from "../loading";

const cookies = new Cookies();

const CancelButton = styled.button`
    background: #309E72;
    font-size: .8em;
    color: white;
    border-radius: 20px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;

    &:hover {
        background: #167E54;
        border: solid 1px #167E54;
    }
`;

const DeleteButton = styled.button`
    background: #309E72;
    font-size: .8em;
    color: white;
    border-radius: 20px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;

    &:hover {
        background: #CA3737;
        border: solid 1px #CA3737;
    }
`;

function SelectorOptions({options}) {
    return(
        options.map(option=>
                <option id={option.id} value={option.value}>
                    {option.name}
                </option>
            )
    );
};

const initialState = {
    skillsArray : [],
    neuroArray : [],
};
    

function ViewOffer({Offer, Institutions}) {

    const [isLoading, setLoading] = useState(true);
    const [DataCharged, setDataCharged] = useState(false);

    const [state, setState] = useState({
        title: Offer.title,
        type: Offer.type,
        schedule: Offer.schedule,
        description: Offer.description,
        skills: Offer.skills.split(','),
        neurodiversities: Offer.neurodiversities.split(','),
        institution: "",
        ...initialState,
    });

    function SelectorSkills({options}) {
        return(
            options.map(option=>
                    <div class="col-auto">
                        <GreenInputActive id={option.id} class="btn" style={{cursor: "default"}}>{option}</GreenInputActive>
                    </div>
                )
        );
    };

    function SelectorNeuro({options}) {
        return(
            options.map(option=>
                    <div class="col-auto">
                        <GreenInputActive id={option.id} class="btn" style={{cursor: "default"}} for={option.id}>{option}</GreenInputActive>
                    </div>
                )
        );
    };

    const getData = async () => {
        let institution;

        Institutions.forEach(element => {
            if(element.id == Offer.institutionId)
                institution = element.name;
        });

        setState({...state, institution: institution});

        setDataCharged(true);
    }

    useEffect(() => {
        if(!DataCharged)
            getData();

        if(DataCharged) 
            setLoading(false);
    });

    const render = () => {
        if (isLoading) return <Loading/>;

        return(       
            <RegisterCard class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <InputLabel  class="form-label">Título</InputLabel>
                            <br/>
                            <RegisterInput id="job-title" type="text" disabled value={state.title}/>
                            <br/>
                            <br/>
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-6">
                            <InputLabel  class="form-label">Tipo de empleo</InputLabel>
                            <br/> 
                            <RegisterSelector class="form-select" id="job-type" disabled value={state.type}>
                                <SelectorOptions options={JobType}/> 
                            </RegisterSelector>
                            <br/>
                            <br/>
                        </div>
                        <div class="col-6">
                            <InputLabel  class="form-label">Horario</InputLabel>
                            <br/> 
                            <RegisterSelector class="form-select" id="job-schedule" disabled value={state.schedule}>
                                <SelectorOptions options={Schedule} /> 
                            </RegisterSelector>
                            <br/>
                            <br/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <InputLabel  class="form-label">Universidad</InputLabel>
                            <br/>
                            <RegisterInput id="job-title" type="text" placeholder="Título" disabled value={state.institution}/>
                            <br/>
                            <br/>
                        </div>
                    </div>

                    <InputLabel  class="form-label">Habilidades</InputLabel>
                    <div class="row">
                        <SelectorSkills options={state.skills}/>
                    </div>

                    <InputLabel  class="form-label">Neurodiversidades</InputLabel>
                    <div class="row">
                        <SelectorNeuro options={state.neurodiversities}/>
                    </div><br/>
                
                    <div class="row">
                        <div class="col-12">
                            <InputLabel  class="form-label">Descripción</InputLabel>
                            <br/>
                            <OfferDescription id="job-description" disabled value={state.description} />
                            <br/>
                        </div>
                    </div>  
                </div>
            </RegisterCard>
        );
    };

    return render();
};



export default ViewOffer;