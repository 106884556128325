import {React, useState, useEffect } from 'react';
import Lorem from '../../assets/images/Lorem';
import {DivContainer, ProfileImg, ProfileImgContainer, ProfileImgBorder, StudentData, StudentDataCard, CardTitle} from '../Student/jobElements';
import {GreenInputActive} from './hhrrElements';
import { getCareers } from '../../controllers/institutionsAndCareers';
import { Loading } from '../loading';
import Capitalize from '../Student/capitalize';
import styled from 'styled-components';

function getAge(dateString) 
{
    var ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds/1000/60/60/24/365); // convert to years
}

const CardHeader = styled.div`
    background:#0B5278;
    border-radius: 15px 15px 0 0;
    padding-top: 1em;
`;

const ProfileRender = styled.div`
    display: flex;
    align-items: center;
    padding: 1em;
`;

const UsernameLabel = styled.label`
    text-align: center;
    font-family: sassoon-sans-std-bold;
    margin: 1em;
    background: white;
    color: black;
    padding: 1.2em 1.5em;
    width: auto;
    line-height: 0em;
    border-radius: 15px;
    box-shadow: 0 8px 8px -5px black;
    margin-top: 13px;
`;

function ViewStudentProfile({Student, Institutions}) {

    const [isLoading, setLoading] = useState(true);
    const [DataCharged, setDataCharged] = useState(false);

    const [state, setState] = useState({
        institution: "",
        career: "",
        skills: [],
        neuros: []
    });

    let studentName = Capitalize(Student.givenNames)+" "+Capitalize(Student.familyNames);

    function SelectorSkills({options}) {
        return(
            options.map(option=>
                    <div class="col-auto">
                        <GreenInputActive id={option.id} class="btn" style={{cursor: "default"}}>{option}</GreenInputActive>
                    </div>
                )
        );
    };

    function SelectorNeuro({options}) {
        return(
            options.map(option=>
                    <div class="col-auto">
                        <GreenInputActive id={option.id} class="btn" style={{cursor: "default"}} for={option.id}>{option}</GreenInputActive>
                    </div>
                )
        );
    };

    const getData = async () => {
        const careers = await getCareers();

        let career;
        let institution;

        Institutions.forEach(element => {
            if(element.id == Student.institutionId)
                institution = element.name;
        });

        careers.forEach(element => {
            if(element.id == Student.careerId)
                career = element.name;
        });

        const skills = Student.skills.split(',');
        const neuros = Student.neurodiversities.split(',');

        setState({...state, career: career, institution: institution, skills: skills, neuros: neuros});

        setDataCharged(true);
    }

    useEffect(() => {
        if(!DataCharged)
            getData();

        if(DataCharged) 
            setLoading(false);
    });

    const render = () => {
        if (isLoading) return <Loading/>;

        return(
            <ProfileRender>
                <DivContainer class="card w-100">
                    <CardHeader class="card-header">
                        
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <ProfileImgBorder>
                                    <ProfileImgContainer>
                                        <ProfileImg src={require('../../assets/images/user.png')}/>
                                    </ProfileImgContainer>
                                </ProfileImgBorder>
                            </div>

                            <div class="row justify-content-center">
                                <UsernameLabel>
                                    {studentName}
                                </UsernameLabel>
                            </div>

                        </div>
                    </CardHeader>

                    <StudentData>
                        <StudentDataCard class="card">
                            <CardTitle class="card-title">Datos Personales</CardTitle>
                            <label>Universidad: {state.institution}</label>
                            <br/>
                            <label>Carrera: {state.career}</label>
                            <br/>
                            <label>Edad: {getAge(Student.dateOfBirth)} años</label>
                            <br/>
                            <label>Actualidad: Cursando {Student.currentGrade}° semestre</label>
                        </StudentDataCard>

                        <StudentDataCard class="card">
                            <CardTitle class="card-title">Acerca de mí</CardTitle>
                            <p>{Student.selfDescription}</p>
                            <div class="row">
                                <CardTitle class="card-title">Habilidades</CardTitle>
                                <SelectorSkills options={state.skills}/>
                            </div>
                            <br/>
                            <div class="row">
                                <CardTitle class="card-title">Condición(es)</CardTitle>
                                <SelectorNeuro options={state.neuros}/>
                            </div>
                        </StudentDataCard>

                        <StudentDataCard class="card">
                            <CardTitle class="card-title">Contacto</CardTitle>
                            <label>Teléfono: {Student.phoneNumber}</label>
                            <br/>
                            <label>Correo: {Student.email}</label>
                        </StudentDataCard>

                    </StudentData>

                </DivContainer>
        </ProfileRender>
        );
    }

    return render();
};



export default ViewStudentProfile;