import styled from 'styled-components';
import { device } from '../../assets/device';

export const ListItem = styled.div`
    align-items: center;
    background: white;
    padding: 1em 1em;
`;

export const JobItem= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    height: 100%;
    padding: 0;
`;

export const JobCard= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    padding: 0.3em;
`;

export const Recommended = styled.p`
    color: RoyalBlue;
    font-size: .9em;
    font-family: sassoon-sans-std-bold;
`;

export const JobTitle = styled.h3`
    font-size: 2.5em;
    margin-bottom: 0px;
    font-family: sassoon-sans-std-bold;
`;

export const JobEmployer = styled.h5`
    font-size: 1.3em;
    margin-bottom: 1.5rem;
    font-family: sassoon-sans-std;
`;

export const JobSalary = styled.h6`
    font-size: 1.2em;
    font-family: sassoon-sans-std-bold;
    margin-bottom: 1rem;
`;

export const JobTime = styled.p`
    font-size: 1em;
    color: Gray;
    margin-bottom:0px;
    font-family: sassoon-sans-std;
`;

export const JobsPage = styled.div`
    display: flex;
    background: white;
    padding: 0 15rem;
`;

export const VerticalLine = styled.div`
    border-left: 5px solid #309E72;
    display: flex;
    align-items: center;
    background: white;
    padding: 1em 1em;
    height: 1000px;
    width: 0%;
`;

export const JobRender = styled.div`
    display: flex;
    align-items: center;
    padding: 1em 1em;
    height: 100%;
 
`;

export const JobDescription = styled.div`
    position: relative;
    :after {
        position: absolute;
        bottom: 0;
        right: 0%;  
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80% 
        );
`;

export const SearchContainer = styled.div`
    color: black;
    margin 1rem;
    border-bottom: 1px solid #309E72;
`;

export const SearchTable = styled.div`
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

export const SearchInput = styled.input`
    padding: 8px;
    font-family: sassoon-sans-std;
    background: white;
    border: solid 1px silver;
    border-radius:10px;
    width:100%;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const GreenButton = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;

`;

export const LightBlueButton = styled.button`
    background: #5B6EFF;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #5B6EFF;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;
`;

export const DarkBlueButton = styled.button`
    background: #0B5278;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #0B5278;
`;

export const WhiteButton = styled.button`
    background: white;
    font-size: 1em;
    color: black;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px white;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;
`;

export const DropdownContainers = styled.div`
    padding: 0px 1em;
`;

export const JobHeader = styled.div`

    background-color: #0B5278;
    border-radius: 14px 14px 0 0;
    color: white;
    border-bottom: 1px solid #0B5278;
    box-shadow: 0 8px 8px -4px Silver;
    height: 25%;
`;

export const JobDetails = styled.div`
    font-size: 16px;
    font-family: sassoon-sans-std;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`;

export const JobInfo = styled.div`
    overflow-y: scroll;
    overfloy-x: hidden;
    padding: 3em;
    height: 75%;
    border-radius: 0 0 14px 14px;
    font-family: sassoon-sans-std;
`;

export const JobImgContainer = styled.div`
    background: #309E72;
    border-radius: 0 0 50% 50%;
    color: white;
    height: 80%;
    margin: 0 2.5em 0 0;
    display: flex;
    justify-content: center;
`;

export const JobImg = styled.img`
    width: auto;
    height:40%;
    margin-top:3em;    
`;

export const JobPrincipalInfo = styled.div`
    padding: 1.3em; 
`;

export const ListTest = styled.div`
    margin-bottom: 1.5em;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const ButtonNav = styled.div`
    background: #309E72;
    border-radius: 15px 15px 0 0;
    padding: .5em 1em;
    font-size: 1em;
    font-family: sassoon-sans-std-bold;
    color: white;
    text-align: center;
    border-bottom: 0px;
    margin-bottom: 0px;

    &:active {
        transition: all 0.3s ease-in-out;
        background: white;
        color: black;
        border-bottom: "0px";
    }
`;

export const DivContainer = styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    height: 100%;
    width: 100%;
    padding: 0;
`;

export const ProfileImg= styled.img`
    height: 92%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export const ProfileImgContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
`;

export const ProfileImgBorder = styled.div`
    background: #309E72;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
`;

export const UsernameLabel = styled.label`
    text-align: center;
    font-family: sassoon-sans-std-bold;
    margin: 1em;
    background: white;
    color: black;
    padding: 1.2em 1.5em;
    width: auto;
    line-height: 0em;
    border-radius: 15px;
    box-shadow: 0 8px 8px -5px black;
    margin-top: -25px;
`;


export const StudentData = styled.div`
    padding:  1.5em;
    background: white;
    border-radius: 0 0 14px 14px;
    font-family: sassoon-sans-std;
`;

export const StudentDataCard = styled.div`
    background: white;
    width: 100%;
    padding: 1em;
    border-radius: 15px;
    border: 1px solid Silver;
    color: black;
    margin-bottom: 1.5em;
`;

export const CardTitle = styled.p`
    text-align: center;
    font-size: 1.5em;
    margin-bottom: .3em;
    color: #309E72;
    font-family: sassoon-sans-std-bold;
`;


export const ProfileView = styled.div`
    width: 100%;
    margin: 0;
    padding: 0 .8em;
`;

export const TasksHeader = styled.nav`
    background: #0B5278; 
    border-radius:15px 15px 0 0;
`;

export const Task = styled.div`
    padding: 1em;
    height: 100%;
    padding-top: 0;
    overflow-y: scroll;
`;

export const CardHeader = styled.div`
    background:#0B5278;
    border-radius: 15px 15px 0 0;
    padding-top: 1em;
    padding-bottom: 2.5em;
`;

//LOGIN ELEMENTS

export const LoginRender = styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: #0B5278;
`;

export const LoginImg= styled.img`
    width: auto;
    height: 50px; 
`;

export const TopContainer = styled.div`
    text-align: center;
    padding-bottom : 1em;
    padding-top: 5%;
    
    @media ${device.tablet} { 
        padding-top: 20%;
      };
`;

export const TopLogo= styled.img`
    width: auto;
    margin-top: 15vh;
    height: 50px;
    margin-bottom: 1em;
    
    @media ${device.tablet} {
        height: auto;
        margin-top: 50%;
        width: 50%;
    }
`;

export const BottomContainer = styled.div`
    text-align: center;
    width: "100%"
`;

export const BottomLogo= styled.img`
    width: 40%;
    margin-top: 20vh;
    height: auto;

    @media ${device.tablet} {
        height: auto;
        margin-top: 30%;
        width: 100%;
    }
`;

export const LoginInput = styled.input`
    padding: .3em 1.2em;
    margin-bottom: 1em;
    background: white;
    border: solid 1px silver;
    border-radius:30px;
    width: 30vh;
    box-shadow: 0 8px 8px -4px silver;
    font-family: sassoon-sans-std;
    font-size: 1.1em;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const RegisterInput = styled.input`
    padding: .3em 1.2em;
    margin-bottom: 1em;
    background: white;
    border: solid 1px silver;
    border-radius:30px;
    width: 100%;
    font-family: sassoon-sans-std;
    font-size: 1.1em;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;


export const InputRender= styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    background: #0B5278;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
`;

export const RegisterBoxRender= styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    background: white;
    justify-content: center;
    align-items: center;
`;

export const InputLabel = styled.label`
    font-family: sassoon-sans-std-bold;
    font-size: 1.5em;
    padding-bottom: 0.5em;

    @media ${device.tablet} { 
        font-size: 1em;
      };
    
`;

export const InputLabelMiniBold = styled.label`
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    color: #484848;

    @media ${device.tablet} { 
        font-size: .5em;
      };
    
`;

export const LoginCard= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    padding: 1em;
    width:400px;
    height:400px;
`;

export const RegisterCard= styled.div`
    background: #0B5278;
    border-radius: 15px;
    border: 1px solid Silver;
    padding: 1em;
    width: 100%;
    color: white
`;

export const RegisterSelector = styled.select`
    padding: .3em 1.2em;
    margin-bottom: 1em;
    background: white;
    border: solid 1px silver;
    border-radius:30px;
    width: 100%;
    font-family: sassoon-sans-std;
    font-size: 1.1em;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const GreenInput = styled.label`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    margin: .5em 0;
    border: solid 1px #309E72;
    cursor:pointer;
    
    :active {
        background: #5B6EFF;
        border: solid 1px #5B6EFF;
    }
`;

export const GreenInputActive = styled.label`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    margin: .5em 0;
    border: solid 1px #309E72;
    cursor:pointer;
    
    :active {
        background: #309E72;
        border: solid 1px #309E72;
    }
`;

export const AboutMe = styled.textarea`
    padding: 8px;
    background: white;
    border: solid 1px silver;
    border-radius:10px;
    width: 100%;
    resize:none;
    height: 450px;
    overflow: hidden;
    box-shadow: 0 8px 8px -4px silver;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const OfferDescription = styled.textarea`
    padding: 8px;
    background: white;
    border: solid 1px silver;
    border-radius:10px;
    width: 100%;
    resize:none;
    height: 250px;
    overflow-x: hidden;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const SubmitButton = styled.button`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1.4em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    width: 70%;
`;

//NEW ITEMS

export const IconContainer = styled.a`
    margin-left: 0.5em;
    color: #309E72;
    &:hover{
        color: #0B5278;
    }
`;