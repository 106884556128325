import styled from 'styled-components';
import { device } from '../../../src/assets/device';

export const LoginRender = styled.div`
    height: 100vh;
    width: 100%;
    background: #0B5278;
`;

export const RegisterRender = styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: #0B5278;
`;

export const StudentInvitationRender = styled.div`
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background: #0B5278;
`;

export const LoginImg= styled.img`
    width: auto;
    height: 50px; 
`;

export const CenterText = styled.div`
    font-family: sassoon-sans-std-bold;
    font-size: 1.5em;
    padding-bottom: 0.5em;
    margin-top: 50px;
    margin-bottom: 10px;
    color: #fff;
    text-align: center;

    @media ${device.tablet} { 
        font-size: 1em;
    };
`;

export const TopContainer = styled.div`
    text-align: center;
    padding-bottom : 1em;
    padding-top: 5%;
    
    @media ${device.tablet} { 
        padding-top: 20%;
    };
`;

export const TopLogo= styled.img`
    width: auto;
    margin-top: 7vh;
    height: 50px;
    margin-bottom: 13vh;
    
    @media ${device.tablet} {
        height: auto;
        width: 50%;
    }
`;

export const BottomContainer = styled.div`
    text-align: center;
    width: "100%";
    background: #0B5278;
`;

export const BottomLogo= styled.img`
    width: 40%;
    margin-top: 13vh;
    height: auto;
    background: #0B5278;

    @media ${device.tablet} {
        height: auto;
        width: 100%;
    }
`;

export const LoginInput = styled.input`
    padding: .3em 1.2em;
    margin-bottom: 1em;
    background: white;
    border: solid 1px silver;
    border-radius:30px;
    width: 80%;
    box-shadow: 0 8px 8px -4px silver;
    font-family: sassoon-sans-std;
    font-size: 1.1em;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const RegisterInput = styled.input`
    padding: .3em 1.2em;
    margin-bottom: 1em;
    background: white;
    border: solid 1px silver;
    border-radius:30px;
    width: 100%;
    box-shadow: 0 8px 8px -4px silver;
    font-family: sassoon-sans-std;
    font-size: 1.1em;
    :focus {
        outline: solid 1px #309E72 !important;
    }

    text-align: center;
`;


export const GreenButton = styled.button`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1.4em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    width: 70%;
`;

export const InputRender= styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    background: #0B5278;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
`;

export const RegisterBoxRender= styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    background: #0B5278;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const InputLabel = styled.label`
    font-family: sassoon-sans-std-bold;
    font-size: 1.5em;
    padding-bottom: 0.5em;

    @media ${device.tablet} { 
        font-size: 1.5em;
      };
    
`;

export const InputLabelMini = styled.label`
    font-family: sassoon-sans-std;
    font-size: 1em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    color: #484848;

    @media ${device.tablet} { 
        font-size: .5em;
      };
    
`;

export const InputLabelMiniBold = styled.label`
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    color: #484848;

    @media ${device.tablet} { 
        font-size: .5em;
      };
    
`;

export const LoginCard= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    padding: 1em;
    width: 400px;
    height: 400px;
`;

export const RegisterCard= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    padding: 1em;
    width: 600px;
    height: 100%;
`;

export const RegisterSelector = styled.select`
    padding: .3em 1.2em;
    margin-bottom: 1em;
    background: white;
    border: solid 1px silver;
    border-radius:30px;
    width: 100%;
    box-shadow: 0 8px 8px -4px silver;
    font-family: sassoon-sans-std;
    font-size: 1.1em;
    :focus {
        outline: solid 1px #309E72 !important;
    }

    text-align: center;
`;

export const GreenInput = styled.label`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    margin: .5em 0;
    border: solid 1px #309E72;
    cursor:pointer;
    
    :active {
        background: #5B6EFF;
        border: solid 1px #5B6EFF;
    }
`;

export const AboutMe = styled.textarea`
    padding: 8px;
    font-family: sassoon-sans-std;
    background: white;
    border: solid 1px silver;
    border-radius:10px;
    width: 100%;
    resize:none;
    height: 150px;
    overflow: hidden;
    box-shadow: 0 8px 8px -4px silver;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const SearchBar = styled.select`
    position: relative;
    padding: .3em 1.2em;
    margin-bottom: 1em;
    background: white;
    border: solid 1px silver;
    border-radius:30px;
    width: 85%;
    box-shadow: 0 8px 8px -4px silver;
    font-family: sassoon-sans-std;
    font-size: 1.1em;
    :focus {
        outline: solid 1px #309E72 !important;
    }
    float: left;
    text-align: center;`
;

export const AddSkill = styled.button`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1.1em;
    color: white;
    border: solid 1px #309E72;
    border-radius: 30px;
    width: 10%;
    float: rigth;
    text-align: center;`
;

export const AddNeuro = styled.button`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1.1em;
    color: white;
    border: solid 1px #309E72;
    border-radius: 30px;
    width: 10%;
    float: rigth;
    text-align: center;`
;

export const GreenLabel = styled.label`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 30px;
    padding: 8px 12px 8px 18px;
    margin: .5em 0;
    border: solid 1px #309E72;
    cursor:pointer;
`;

export const LabelRed = styled.label`
    background: red;
    color: white;
    width: 1.5em;
    border-radius: 30px;
`;
