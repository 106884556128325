import React from "react";
import { TopLogo } from "./Login/login-registerElements";
import { SyncLoader } from "react-spinners";

export const Loading = () => {
    return (
        <div class="container-fluid" style={{ background: "#0B5278", color: "white", height: "100vh", textAlign: "center", }}>
            <div class="row align-items-center" style={{ height: "80vh", textAlign: "center" }}>
                <div class="col">
                    <picture>
                        <TopLogo src={require('../assets/images/TextLogo.png')} />
                    </picture>
                    <SyncLoader color={"#fff"} />
                </div>
            </div>
        </div>
    );
};

export const WhiteLoading = () => {
    return (
        <div class="container-fluid" style={{ background: "white", color: "black", height: "100%", textAlign: "center", }}>
            <div class="row align-items-center" style={{ height: "100%", textAlign: "center" }}>
                <div class="col">
                    <picture>
                        <TopLogo src={require('../assets/images/TextLogoBlue.png')} />
                    </picture>
                    <SyncLoader color={"#0B5278"} />
                </div>
            </div>
        </div>
    );
};

export const OopsLoading = ({ type }) => {
    return (
        <div class="container-fluid" style={{ background: "white", color: "black", height: "100%", textAlign: "center", }}>
            <div class="row align-items-center" style={{ height: "100%", textAlign: "center" }}>
                <div class="col">
                    <h2>
                        ¡Parece que aún no hay {type} por aquí!
                    </h2>
                </div>
            </div>
        </div>
    );
}

export const OopsJobLoading = ({ type }) => {
    return (
        <div class="container-fluid" style={{ background: "white", color: "black", height: "100%", textAlign: "center", }}>
            <div class="row align-items-center" style={{ height: "100%", textAlign: "center" }}>
                <div class="col">
                    <h2>
                        ¡Parece que aún no hay una {type} por aquí!
                    </h2>
                </div>
            </div>
        </div>
    );
}
