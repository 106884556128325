// ----------------------------------- Librarys -----------------------------------
import React, { useState, useEffect } from "react";
import { getEmployerById, getEmployers, getEmployerUserData } from "../../controllers/employers";
//import EditOffer from "./editOffer"; // EditarEstudiante
// ------------------------------------ Styles ------------------------------------
import { ListItem, Card, Title, Description, IconContainer } from "./operatorElements";
import { MdDeleteOutline } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { Loading, OopsLoading } from "../loading";

//import { getInstitutions } from "../../controllers/institutionsAndCareers";
import ViewEmployerProfile from "./viewEmployerProfile";
import { deleteEmployerById } from "../../controllers/employers";
import { Toast } from "../alert";
import { deleteUserById } from "../../controllers/users";


// --------------------------------------------------------------------------------

const Swal = require('sweetalert2');

// TODO: Poner los botones verdes al inicioo

function EmployersBox() {

    const [isLoading, setLoading] = useState(true);
    const [employersDataCharged, setEmployersDataCharged] = useState(false);
    const [state, setState] = useState({
        employersList: [],
    });

    const getEmployersData = async () => {
        const data = await getEmployers();



        setState({ ...state, employersList: data, });
        setEmployersDataCharged(true);
    };

    const handleDeleteEmployer = async (employer) => {

        Swal.fire({
            title: '¿Está seguro de que desea eliminar al empleador '+ employer.name +'?',
            text: "¡No se podrán revertir los cambios!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const employerUser = await getEmployerUserData(employer.id);
                    const response = await deleteEmployerById(employer.id);

                    const response2 = await deleteUserById(employerUser.userId);

                    if(response.ok && response2.ok){
                        Toast.fire(
                            '¡Eliminado!',
                            employer.name + ' ha sido eliminado.',
                            'success'
                        )

                        document.location.reload();
                    }
                        
                    else
                        Toast.fire(
                            'Error',
                            employer.name + ' no ha sido eliminado.',
                            'error'
                        )
                }
        } )
    }

    useEffect(() => {
        if (!employersDataCharged)
            getEmployersData();
        else
            setLoading(false);

          
    });

    const render = () => {
        if (isLoading) return <Loading />;

        else if (state.employersList[0] === undefined) return <OopsLoading type={"empleadores"} />;

        return (state.employersList.map((employer) => {
            return (
                <ListItem key={employer.id}>
                    <Card className="card w-100">
                        <div className="card-body">
                            <div className="container-fluid" style={{ paddingInline: "0" }}>
                                <div className="row">
                                    <div className="col-10">
                                        <Title>{employer.name}</Title>
                                    </div>
                                    <div className="col-2" style={{ textAlign: "right", }}>
                                        <IconContainer>
                                            <FiEye size={24} cursor="pointer" data-bs-toggle="modal" data-bs-target={"#view-"+ employer.id}/>
                                        </IconContainer>
                                        <IconContainer >
                                            <MdDeleteOutline size={30} cursor="pointer" onClick={() => handleDeleteEmployer(employer)}/>
                                        </IconContainer>
                                    </div>
                                </div>
                            </div>
                            <Description>{employer.description}</Description>
                        </div>
                    </Card>

                    <div  class="modal fade" id={"view-"+ employer.id} aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "50%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Perfil de empleador</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <ViewEmployerProfile Employer={employer}/>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="modal fade" id={"delete-"+ student.id} aria-labelledby="Eliminar Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content" style={{background: "white", color: "black", fontSize: "1.5em"}}>
                                <div className="modal-body">
                                    <p style={{fontFamily: "sassoon-sans-std-bold"}}>¿Seguro que deseas borrar esta oferta de trabajo?</p>
                                    <br/>
                                    <p style={{textAlign : "center"}}>{student.title}</p>
                                </div>
                                <div className="modal-footer" style={{background: "#0B5278"}}>
                                    <CancelButton type="button" className="btn" data-bs-dismiss="modal" >Cancelar</CancelButton>
                                    <DeleteButton type="button" className="btn" role="button" data-bs-dismiss="modal" onClick={() => handleDelete(student.id)}>Eliminar</DeleteButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  className="modal fade" id={"edit-"+ student.id} aria-labelledby="Eliminar Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "35%" }}>
                            <div className="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div className="modal-body">
                                    <EditOffer Offer={student} Skills={Skills} Neuro={Neuro}/>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </ListItem>

            );
        }));
    };

    return render();
};

export default EmployersBox;