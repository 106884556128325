import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Schedule } from "../../assets/schedule";
import { addSkill } from "../../controllers/skills";
import { JobType } from "../../assets/jobType";
import {addOffer} from "../../controllers/employerUser";
import { Loading } from '../loading';
import { Toast } from "../alert";
import {RegisterInput, OfferDescription, RegisterBoxRender,RegisterCard, InputLabel, InputLabelMiniBold, RegisterSelector, GreenInput, AboutMe, SubmitButton} from "./hhrrElements";
import { SearchBar, AddSkill, AddNeuro, LabelRed, GreenLabel, GreenButton } from "../Login/login-registerElements";
import Swal from "sweetalert2";

function SelectorOptions({options}) {
    return(
        options.map(option=>
                <option id={option.id} value={option.value}>
                    {option.name}
                </option>
            )
    );
};

function SelectorOptionsInstitutions({ options }) {
    return (
        options.map(option =>
            <option key={option.id} value={option.id}>
                {option.description}
            </option>
        )
    );
};

function SearchBarSkills (props) {
    let SkillsList = props.SkillsList;
    let stateF = props.stateF;
    let setStateF = props.setStateF;
    let skillsAdded = stateF.skillsAdded;

    function SelectorSkills({ options }) {
        return options.map((option) => (
            <div className="col-auto">
                <GreenLabel
                    id={option + "Id"}
                    value={option}
                    className="btn btn-primary"
                >
                    {option}&emsp;
                    <LabelRed onClick={() => deleteSkill(option)}>X</LabelRed>
                </GreenLabel>
            </div>
        ));
    }

    const [state, setState] = useState({
        skill: "",
        getSkillsDataBool: false,
    });

    function formChange(e) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, skill: e.target.value });
    }

    function addSkill() {
        if (!skillsAdded.find((skill) => skill === state.skill)) {
            if (state.skill !== "") {
                skillsAdded.push(state.skill);
                setStateF({ ...stateF, skillsAdded: skillsAdded });
            }
        }
    }

    function deleteSkill(option) {
        skillsAdded = skillsAdded.filter((skill) => skill !== option);
        setStateF({ ...stateF, skillsAdded: skillsAdded });
    }

    const render = () => {
        return (
            <div className="searchBar">
                <div className="searchInputs">
                    <SearchBar style={{boxShadow: "none"}}
                        id={props.id}
                        className="mdb-select md-form"
                        searchable="Busca tus habilidades..."
                        value={state.skill}
                        onChange={(event) => formChange(event)}
                    >
                        <option value="" disabled selected>
                            Busca tus habilidades...
                        </option>
                        <SelectorOptions options={SkillsList} />
                    </SearchBar>
                    <AddSkill
                        type="button"
                        className="btn btn-primary"
                        style={{marginLeft: "1em"}}
                        onClick={() => addSkill()}
                    >
                        +
                    </AddSkill>
                </div>
                <br />
                <div class="row">
                    <SelectorSkills options={skillsAdded} />
                </div>
            </div>
        );
    };

    return render();
}

function SearchBarNeurodiversities({ NeurodiversitiesList, stateF, setStateF }) {

    let neurosAdded = stateF.neurosAdded;

    function SelectorNeuros({ options }) {
        return (
            options.map(option =>
                <div className="col-auto">
                    <GreenLabel id={option + "Id"} value={option} className="btn btn-primary">{option}&emsp;<LabelRed onClick={() => deleteNeuro(option)}>X</LabelRed></GreenLabel>
                </div>
            )
        );
    };

    const [isLoading, setLoading] = useState(true);

    const [state, setState] = useState({
        neuro: "",
        neuros: [],
        getNeurosDataBool: false,
    });

    const getNeurosData = async () => {
        const neuroData = NeurodiversitiesList;
        setState({ ...state, neuros: neuroData, getNeurosDataBool: true, });
    }

    function formChange(e) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, neuro: e.target.value });
    }

    function addNeuro() {
        if (!neurosAdded.find((neuro) => neuro === state.neuro)) {
            if(state.neuro !== ""){
                neurosAdded.push(state.neuro);
                setStateF({ ...stateF, neurosAdded: neurosAdded });
            }
        }
    }

    function deleteNeuro(option) {
        neurosAdded = neurosAdded.filter(neuro => neuro !== option);
        setStateF({ ...stateF, neurosAdded: neurosAdded });
    }

    useEffect(() => {
        if (!state.getNeurosDataBool) getNeurosData();

        if (state.neuros[0] !== "") { setLoading(false); };
    });

    const render = () => {

        if (isLoading) return <Loading />;

        return (
            <div className='searchBar'>
                <div className='searchInputs' >
                    <SearchBar className="mdb-select md-form" style={{boxShadow: "none"}} searchable="Busca las neurodiversidades..." value={state.neuro} onChange={(event) => formChange(event)}>
                        <option value="" disabled selected>Busca las neurodiversidades...</option>
                        <SelectorOptions options={state.neuros} />
                    </SearchBar>
                    <AddNeuro type="button" className="btn btn-primary" style={{marginLeft: "1em"}} onClick={() => addNeuro()}>+</AddNeuro>
                </div> <br />
                <div class='row'>
                    <SelectorNeuros options={neurosAdded} />
                </div>
            </div>
        );
    };

    return render();
};

const initialState = {
    title: "",
    type: "practice",
    schedule: "HalfTime",
    description: "",
    skills: "",
    neurodiversities: "",
    institutionId: 0,
}

function OfferBoxBlank({ SkillsList, InstitutionsList, NeurodiversitiesList }) {

    const [state, setState] = useState({
        skillsAdded: [],
        neurosAdded: [],
        ...initialState
    });

    const addNewSkill = async () => {
        Swal.fire({
            title: 'Nueva habilidad',
            html: `<input type="text" id="name" class="swal2-input" style="width: 80%" placeholder="Nombre">
            <textarea type="text" id="description" class="swal2-textarea" style="width: 80%" placeholder="Descripción">`,
            confirmButtonText: 'Agregar',
            focusConfirm: false,
            preConfirm: () => {
                const name = Swal.getPopup().querySelector('#name').value;
                const description = Swal.getPopup().querySelector('#description').value;
                if (!name) {
                    Swal.showValidationMessage(`Llena por lo menos el nombre`);
                }
                return { name: name, description: description }
            }
        }).then( async (result) => {
            let name = result.value.name;
            let description = result.value.description;
            let exists = false;
            
            const select = document.getElementById('SearchBarSkills');

            for(let i=0; i<select.options.length; i++){
                if(select.options[i].innerHTML == name){
                    exists = true;
                }
            }

            if(exists){
                Toast.fire({
                    icon: "error",
                    title: "Esta habilidad ya existe", // ? Regresar otro mensaje
                });
            }
            else{
                const response = await addSkill({ name, description });

                if (response.ok) {

                    var opt = document.createElement('option');
                    opt.value = name;
                    opt.innerHTML = name;

                    select.appendChild(opt);

                    Toast.fire({
                        icon: "success",
                        title: "Habilidad agregada!",
                    });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Error", // ? Regresar otro mensaje
                    });
                }
            }
        })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await addOffer({title: state.title, skills: state.skillsAdded.toString(), description: state.description, neurodiversities: state.neurosAdded.toString(), type: state.type, schedule: state.schedule, institutionId: parseInt(state.institutionId)});

        if (response.ok) {
            Toast.fire({
                icon: "success",
                title: "Postulación creada!",
            });
        }
        else {
            Toast.fire({
                icon: "error",
                title: "Error", // ? Regresar otro mensaje
            });
        }

        document.location.reload();
    };

    function formChange( e, field ) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, [field]: e.target.value });
    }

    return(
        <RegisterBoxRender class="container">
            <RegisterCard class="card">
                <div class="card-body">
                    <form class="was-validated" onSubmit={handleSubmit}>
                        <div class="row">
                            <div class="col-12">
                                <InputLabel  class="form-label">Título</InputLabel>
                                <br/>
                                <RegisterInput id="job-title" type="text" placeholder="Título" required value={state.title} onChange={(event) => formChange(event, "title")}/>
                                <div class="invalid-feedback">*Campo requerido</div>
                                <br/>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-6">
                                <InputLabel  class="form-label">Tipo de empleo</InputLabel>
                                <br/> 
                                <RegisterSelector class="form-select" id="job-type" value={state.type} onChange={(event) => formChange(event, "type")}>
                                    <SelectorOptions options={JobType} /> 
                                </RegisterSelector>
                                <br/>
                                <br/>
                            </div>
                            <div class="col-6">
                                <InputLabel  class="form-label">Horario</InputLabel>
                                <br/> 
                                <RegisterSelector class="form-select" id="job-schedule" value={state.schedule} onChange={(event) => formChange(event, "schedule")}>
                                    <SelectorOptions options={Schedule}/> 
                                </RegisterSelector>
                                <br/>
                                <br/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <InputLabel className="form-label">Centro Universitario</InputLabel>
                                <RegisterSelector class="form-select" onChange={(e) => setState({ ...state, institutionId: e.target.value })} style={{textAlign: "center"}} required>
                                    <option value="" disabled selected>Selecciona tu centro universitario</option>
                                    <SelectorOptionsInstitutions style={{textAlign: "left"}} options={InstitutionsList} />
                                </RegisterSelector>
                                <div class="invalid-feedback">*Campo requerido</div>
                                <br />
                            </div>
                        </div>
                        <br/>

                        <InputLabel  class="form-label">Habilidades</InputLabel>
                        <div class="row">
                            <SearchBarSkills id="SearchBarSkills" SkillsList={SkillsList} stateF={state} setStateF={setState}/>
                        </div>
                        <br/>

                        <div className="row justify-content-center">
                            <div className="col-12" style={{ textAlign: "center" }}>
                                <br />
                                <GreenButton
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ fontSize: "1em" }}
                                    onClick={addNewSkill}
                                >
                                    Agregar nueva habilidad
                                </GreenButton>
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>

                        <InputLabel  class="form-label">Neurodiversidades</InputLabel>
                        <div class="row">
                            <SearchBarNeurodiversities NeurodiversitiesList={NeurodiversitiesList} stateF={state} setStateF={setState}/>
                        </div>
                        <br/>
                    
                        <div class="row">
                            <div class="col-12">
                                <InputLabel  class="form-label">Descripción</InputLabel>
                                <br/>
                                <OfferDescription id="job-description" placeholder="Agrega la información de la vacante" value={state.description} onChange={(event) => formChange(event, "description")}/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </div>  

                        <div class="row justify-content-center">
                            <div class="col-12" style={{textAlign: "center"}}> 
                                <SubmitButton type="submit" class="btn btn-primary">Postular</SubmitButton>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </form>
                </div>
            </RegisterCard>
        </RegisterBoxRender>
    );
};

export default OfferBoxBlank;