//import React from "react";
import config from "../config/default.json";
import { accessToken } from "./login";
import Cookies from "universal-cookie";
import { StudentData } from "../Views/Student/jobElements";

const cookies = new Cookies();

const getUrl = (path) => {
  return `${config["entender-api"].url}${path}`;
};

export const applyOffer = async ({jobOfferId, employerId}) => {

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
    body: JSON.stringify({ studentId: cookies.get("studentId"), jobOfferId: jobOfferId, employerId: employerId}),
  };


  const response = await fetch(getUrl("/apply-offer"), requestOptions);
  const appliedOffer = await response.json();
  
  return appliedOffer;
};

export const deleteApplication = async (applicationId) => {

  const requestOptions = {
      method: 'DELETE',
      mode: "cors",
      headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("accessToken"),
      }),
  };
  const response = await fetch(getUrl("/job-application/"+applicationId), requestOptions);

  return response;
}

export const getApplicationsByStudent = async (studentId) => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  }

  const response = await fetch(getUrl("/job-applications/findByStudentId/"+studentId), requestOptions);

  const applications = await response.json();

  return applications;
};

export const getApplicationsByJobOffer = async (jobOfferId) => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  }

  const response = await fetch(getUrl("/job-applications/findByOfferId/"+jobOfferId), requestOptions);

  const applications = await response.json();

  return applications;
};

export const getApplicationsByJobOfferAndStudent = async (jobOfferId,studentId) => {
  
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  }

  const response = await fetch(getUrl("/job-applications/findByStudentAndOffer?offerId="+jobOfferId+"&studentId="+studentId), requestOptions);

  const applications = await response.json();

  return applications;
};

// Saved Offers

export const saveOffer = async ({jobOfferId, employerId}) => {

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
    body: JSON.stringify({ studentId: cookies.get("studentId"), jobOfferId: jobOfferId, employerId: employerId}),
  };
  const response = await fetch(getUrl("/save-offer"), requestOptions);
  const saveddOffer = await response.json();
  
  return saveddOffer;
};

export const deleteSave = async (savedId) => {

  const requestOptions = {
      method: 'DELETE',
      mode: "cors",
      headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("accessToken"),
      }),
  };

  const response = await fetch(getUrl("/job-saved/"+savedId), requestOptions);

  return response;
}

export const getSavesByJobOfferAndStudent = async (jobOfferId,studentId) => {
  
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  }

  const response = await fetch(getUrl("/job-saved/findByStudentAndOffer?offerId="+jobOfferId+"&studentId="+studentId), requestOptions);

  const saves = await response.json();

  return saves;
};

export const getSavesByStudent = async (studentId) => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  }

  const response = await fetch(getUrl("/job-saved/findByStudentId/"+studentId), requestOptions);

  const saves = await response.json();

  return saves;
};
