import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
  return `${config["entender-api"].url}${path}`;
};

const getNeurodiversities = async () => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/neurodiversities"), requestOptions);
  const neurodiversities = await response.json();
  
  return neurodiversities;
};

export default getNeurodiversities;
