import React, { useEffect, useState } from 'react';
import {JobRender, JobItem, JobTitle, SaveButton, WhiteButton, JobHeader, JobDetails, JobInfo, JobImgContainer, JobImg, JobPrincipalInfo, RedButton, SaveButtonErase} from './jobElements';
import { FaHeart } from 'react-icons/fa';
import Cookies from 'universal-cookie';
import { applyOffer, deleteApplication, getApplicationsByJobOfferAndStudent, saveOffer, deleteSave, getSavesByJobOfferAndStudent } from '../../controllers/applications';

const cookies = new Cookies();
let application = [];
let saved = [];
//let typeOffer;

function JobOffer({ offer, employer }) {

    const [state, setState] = useState({
        actualOffer: "",
        typeOffer: "",
        scheduleOffer: "",
    });

    const handleApply = async () => {
        await applyOffer({jobOfferId: offer.id, employerId: employer.id});
        checkSaveAndApplication();
    };

    const handleCancel = async () => {
        await deleteApplication(application[0].id);
        checkSaveAndApplication();
    };

    const handleSave = async () => {
        await saveOffer({jobOfferId: offer.id, employerId: employer.id});
        checkSaveAndApplication();
    };

    const handleErase = async () => {
        await deleteSave(saved[0].id);
        checkSaveAndApplication();
    };

    const checkSaveAndApplication = async () => {
        
        let typeOffer;
        let scheduleOffer;

        application = await getApplicationsByJobOfferAndStudent(offer.id,cookies.get("studentId"));
        saved = await getSavesByJobOfferAndStudent(offer.id,cookies.get("studentId"));

        switch(offer.type) {
            case "intern":  typeOffer = "Becario"; break;
            case "practice": typeOffer = "Práctica profesional"; break;
            case "job": typeOffer = "Empleo"; break;
        }

        switch(offer.schedule) {
            case "HalfTime":  scheduleOffer = "Medio tiempo"; break;
            case "FullTime": scheduleOffer = "Tiempo completo"; break;
        }

        setState({...state, actualOffer : offer.id, typeOffer: typeOffer,scheduleOffer: scheduleOffer});
    };

    useEffect(() => {
        if(state.actualOffer !== offer.id)
            checkSaveAndApplication();
    });

    const render = () => {
        return (
            <JobRender>
                <JobItem className="card w-100">
                    <JobHeader className="card-Header-Job-body">
                        <div className="row" style={{top: 100}}>
                            <div className="col-md-8">
                                <JobPrincipalInfo className="container">
                                <JobTitle>{offer.title}</JobTitle>
                                <JobDetails>
                                    <label> {employer.name} </label>
                                    <br/>
                                    <label> {state.typeOffer} - {state.scheduleOffer}</label>
                                </JobDetails>  
                                {(application.length > 0? 
                                <RedButton id={"cancel-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleCancel() }>Cancelar Solicitud</RedButton>
                                :
                                <WhiteButton id={"apply-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleApply() }>Mandar Solicitud</WhiteButton>
                                )}

                                {(saved.length > 0? 
                                <SaveButtonErase id={"erase-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleErase() }><FaHeart/></SaveButtonErase>
                                :
                                <SaveButton id={"save-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleSave() }><FaHeart/></SaveButton>
                                )}
                                
                                </JobPrincipalInfo>
                            </div>
                            <div className="col-md-4" style={{padding:0}}>
                                <JobImgContainer>
                                    <JobImg src={require("../../assets/images/" + employer.logoUrl + ".png")}/>
                                </JobImgContainer>
                            </div>
                        </div>
                    </JobHeader>
                <JobInfo className="container">
                    {offer.description}
                </JobInfo>
                </JobItem>
            </JobRender>
        );
    };
    return render();

};

export default JobOffer;