import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authenticate } from "../../controllers/login";
import { Toast } from "../alert";
import { LoginInput, GreenButton, InputRender, InputLabel, LoginCard } from "./login-registerElements";
import { FiEye } from "react-icons/fi";

function LoginBox() {
  const [userData, setUser] = useState({
    username: "",
    password: "",
  });

  const onChange = ({ currentTarget }) => {
    setUser({...userData, password: currentTarget.value});
  }

  const [shown, setShown] = useState(false);

  const switchShown = () => setShown(!shown);

  let navigate = useNavigate();

  const HandleSubmit = async (event) => {
    event.preventDefault();
    const { response, content } = await authenticate(userData);

    if (response.status == 301) {
      
      if(content.role == "STUDENT")
      {
        Toast.fire({
          icon: "success",
          title: "Realiza tu registro!",
        });

        navigate("/studentRegister",{ replace: true });
      }
      if(content.role == "EMPLOYER")
      {
        Toast.fire({
          icon: "success",
          title: "Realiza tu registro!",
        });

        navigate("/employerRegister",{ replace: true });
      }
      if(content.role == "OPERATOR")
      {
        Toast.fire({
          icon: "success",
          title: "Bienvenido Administrador!",
        });
        
        navigate("/operator/administration",{ replace: true });
      }
    } else if (response.status == 200) {
      Toast.fire({
        icon: "success",
        title: "Bienvenido " + content.givenNames,
      });

      if(content.role == "STUDENT")
        navigate("/student/jobs",{ replace: true });
      else if(content.role == "EMPLOYER")
        navigate("/employer/profile",{ replace: true });
      
    } else {
      Toast.fire({
        icon: "error",
        title: content.message,
      });
    }

  };

  return (
    <InputRender className="container">
      <LoginCard className="card">
        <div className="card-body">
          <form class="was-validated" onSubmit={HandleSubmit}>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <InputLabel className="form-label">Usuario</InputLabel>
                <br />
                <LoginInput
                  required
                  type="text"
                  id="usernameInput"
                  placeholder="Usuario"
                  value={userData.username}
                  onChange={(e) => setUser({...userData, username: e.target.value})}
                />
                <br />
                <InputLabel className="form-label" style={{marginTop: "10px"}}>Contraseña</InputLabel>
                <br />
                <LoginInput
                  required
                  type={shown ? 'text' : 'password'}
                  id="passwordInput"
                  placeholder="********"
                  value={userData.password}
                  onChange={onChange}
                />
                <FiEye 
                  size={24}
                  cursor="pointer" 
                  onClick={switchShown}
                  style={{position: 'absolute', right: '80px', marginTop: '7px'}}>
                  {shown ? 'Ocultar' : 'Mostrar'}
                </FiEye>
                <br />
                <br />
                <GreenButton
                  type="submit"
                  className="btn btn-primary"
                  style={{marginTop: "10px"}}
                >
                  Iniciar Sesión
                </GreenButton>
                <br />
                <br />
              </div>
            </div>
          </form>
        </div>
      </LoginCard>
    </InputRender>
  );
}

export default LoginBox;
