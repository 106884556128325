import React, {useState, useEffect} from 'react';
import {ListItem, JobCard, JobTitle, JobEmployer, JobTime,  GreenButton} from './hhrrElements';
import Cookies from 'universal-cookie';
import { OopsLoading, WhiteLoading } from '../loading';
import { getMyApplications } from '../../controllers/employerUser';
import { getStudents } from '../../controllers/studentInfo';
import { applyOffer } from '../../controllers/applications';
import ViewStudentProfile from './viewStudentProfile';
import ViewOffer from './viewOffer';

const cookies = new Cookies();

function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function ApplicationItems({Offers, Applications, Students, Institutions}) {

 const render = () => {

    if (Applications[0] === undefined) return <OopsLoading type={"postulaciones"}/>;

    return (
        Applications.map((application) => {
            const offerData = Offers.filter((offer) => offer.id === application.jobOfferId);
            const studentData = Students.filter((student) => student.id === application.studentId);

            let studentName = Capitalize(studentData[0].givenNames)+" "+Capitalize(studentData[0].familyNames);

            let skills = offerData[0].skills.split(',');
            let neuro = offerData[0].neurodiversities.split(',');

            return (
                <ListItem>
                    <JobCard class="card w-100">
                        <div class="card-body">

                            <JobTitle>{offerData[0].title}</JobTitle>
                            <JobEmployer>{studentName}</JobEmployer>                    
                            <div class="container" style={{textAlign:"center"}}>
                                <div class="row justify-content-center">
                                    <div class="col">
                                        <GreenButton data-bs-toggle="modal" data-bs-target={"#view-"+ offerData[0].id}>Oferta de Trabajo</GreenButton>
                                    </div>
                                    <div class="col">
                                        <GreenButton data-bs-toggle="modal" data-bs-target={"#view-"+ studentData[0].id}>Perfil de Estudiante</GreenButton> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </JobCard>

                    <div  class="modal fade" id={"view-"+ offerData[0].id} aria-labelledby="Ver Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "35%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Oferta de trabajo</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <ViewOffer Offer={offerData[0]} Skills={skills} Neuro={neuro} Institutions={Institutions}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  class="modal fade" id={"view-"+ studentData[0].id} aria-labelledby="Ver Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "35%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Perfil de estudiante</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <ViewStudentProfile Student={studentData[0]} Institutions={Institutions}/>
                                </div>
                            </div>
                        </div>
                    </div>


                </ListItem>

                
            );
        }
        )
    )
 };

 return render();
};



export default ApplicationItems;