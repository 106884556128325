import React, { useEffect, useState } from 'react';
import {JobRenderRes, JobItem, JobTitle, SaveButton, WhiteButtonRes, JobHeader, JobDetails, JobInfo, JobImgContainerRes, JobImgRes, JobPrincipalInfoRes, RedButtonRes, SaveButtonErase, GreenButton} from './jobElements';
import { FaHeart } from 'react-icons/fa';
import Cookies from 'universal-cookie';
import { applyOffer, deleteApplication, getApplicationsByJobOfferAndStudent, saveOffer, deleteSave, getSavesByJobOfferAndStudent } from '../../controllers/applications';

const cookies = new Cookies();
let application = [];
let saved = [];

function JobOfferRes({ offer, employer }) {


    const [state, setState] = useState({
        actualOffer : "",
        typeOffer: "",
        scheduleOffer: "",
    });

    const handleApply = async () => {
        await applyOffer({jobOfferId: offer.id, employerId: employer.id});
        checkSaveAndApplication();
    };

    const handleCancel = async () => {
        await deleteApplication(application[0].id);
        checkSaveAndApplication();
    };

    const handleSave = async () => {
        await saveOffer({jobOfferId: offer.id, employerId: employer.id});
        checkSaveAndApplication();
    };

    const handleErase = async () => {
        await deleteSave(saved[0].id);
        checkSaveAndApplication();
    };

    const checkSaveAndApplication = async () => {
        let typeOffer;
        let scheduleOffer;

        application = await getApplicationsByJobOfferAndStudent(offer.id,cookies.get("studentId"));
        saved = await getSavesByJobOfferAndStudent(offer.id,cookies.get("studentId"));
        
        switch(offer.type) {
            case "intern":  typeOffer = "Becario"; break;
            case "practice": typeOffer = "Práctica profesional"; break;
            case "job": typeOffer = "Empleo"; break;
        }
    
        switch(offer.schedule) {
            case "HalfTime":  scheduleOffer = "Medio tiempo"; break;
            case "FullTime": scheduleOffer = "Tiempo completo"; break;
        }

        setState({...state, actualOffer : offer.id, typeOffer: typeOffer, scheduleOffer: scheduleOffer});
    };

    useEffect(() => {
        if(state.actualOffer !== offer.id)
            checkSaveAndApplication();            
    });

    const render = () => {
        return (
            <JobRenderRes>
                <JobItem className="card w-100">
                    <JobHeader className="card-Header-Job-body">
                        <div className="row" style={{top: 100}}>
                            <div>
                                <JobPrincipalInfoRes className="container">
                                    <JobImgContainerRes>
                                        <JobImgRes src={require("../../assets/images/" + 
                                        employer.logoUrl + 
                                        ".png")}/>
                                    </JobImgContainerRes>

                                    <JobTitle style={{fontSize: "1.5em"}}>{offer.title}</JobTitle>
                                    <JobDetails>
                                        <label> {employer.name} </label>
                                        <br/>
                                        <label>{state.typeOffer} - {state.scheduleOffer}</label>
                                    </JobDetails>  
                                    {(application.length > 0? 
                                    <RedButtonRes id={"cancel-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleCancel() }>Cancelar Solicitud</RedButtonRes>
                                    :
                                    <WhiteButtonRes id={"apply-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleApply() }>Mandar Solicitud</WhiteButtonRes>
                                    )}

                                    {(saved.length > 0? 
                                    <SaveButtonErase id={"erase-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleErase() }><FaHeart/></SaveButtonErase>
                                    :
                                    <SaveButton id={"save-"+offer.id} type="button" class="btn btn-primary" onClick={ () => handleSave() }><FaHeart/></SaveButton>
                                    )}
                                </JobPrincipalInfoRes>
                            </div>
                        </div>
                    </JobHeader>
                <JobInfo className="container">
                    {offer.description}
                </JobInfo>
                </JobItem>
            </JobRenderRes>
        );
    };
    return render();

};

export default JobOfferRes;