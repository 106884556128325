import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
    return `${config["entender-api"].url}${path}`;
};

export const getInstitutions = async () => {
    const requestOptions = {
        method: "GET",
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
    };

    const response = await fetch(getUrl("/institutions"), requestOptions);
    const institutions = await response.json();

    return institutions;
};

export const getCareers = async () => {
    const requestOptions = {
        method: "GET",
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
    };

    const response = await fetch(getUrl("/careers"), requestOptions);
    const careers = await response.json();

    return careers;
};
