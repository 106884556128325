import React from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";

import logoImg from "../assets/images/TextLogo.png";

import {
  Nav,
  NavLogo,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  ProfileBtnLink,
} from "./navbarElements";
import { LogOff, LogOffRes } from "./logOff";

const cookies = new Cookies();

const EntenderLogo = styled.div`
  background-image: url(${logoImg});
  background-size: 100%;
  width: 200px;
  height: 40px;
  background-position: center;
`;

const ProfilePicture = styled.img`
  height: 2em;
  border-radius:50%;
  margin-right: 0.5em;
`;

/*

          <NavLink to="/createJob" activestyle={{ color: "black" }}>
            Create Job
          </NavLink>
          <NavLink to="/createStudent" activestyle={{ color: "black" }}>
            Create Student
          </NavLink>


*/

const Navbar = () => {

  const cookieKeys = ["accessToken", "userId", "employerId", "employerUserId", "employerName",
  "employerLogo", "email", "description"];

  return (
    <>
      <Nav>
        <NavLogo to="/employer/profile">
          <EntenderLogo />
        </NavLogo>
        <Bars className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{background: "#e4e4e4"}}>
              <li><LogOffRes CookieKeys={cookieKeys}/></li>
          </ul>

        <NavMenu>
          <NavBtn>
            <ProfileBtnLink to="/employer/profile">
              <ProfilePicture src={require('../assets/images/'+cookies.get("employerLogo") +'.png')}/>
                {cookies.get("employerName")}
              </ProfileBtnLink>
              <LogOff CookieKeys={cookieKeys}/>
          </NavBtn>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
