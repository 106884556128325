import React from 'react';
import {ListItem, JobCard, JobTitle, JobEmployer, JobSalary, JobTime, JobDescription, ButtonShowMore, ButtonShowMoreRes, Recommended} from './jobElements';
import JobOfferRes from './jobOfferRes';

const Today = Date.parse(new Date());
let screenSmall = false;

function JobItem({offers,employers,selectedOffer,recommendedOffer}) {

    function onTrigger(event) {
        selectedOffer(event);
    }

    return offers.map((offer) => {
        let employer = employers.filter((employer) => {
            return employer.id === offer.employerId;
        });
        

        let created = Date.parse(offer.createdAt);
        let difference = Today - created;
        let date = Math.ceil(difference / (1000 * 3600 * 24));
        let description = offer.description.substring(0,150);
        let typeOffer;

        switch(offer.type){
            case "intern": typeOffer = "Becario"; break;
            case "practice": typeOffer = "Práctica profesional"; break;
            case "job": typeOffer = "Empleo"; break;
        }

        if(offer.id === recommendedOffer.id)
        {
            return (
                <ListItem  key={offer.id} value={offer.id} >
                    <JobCard className="card w-100">
                        <div className="card-body">
                        <Recommended>Recomendado</Recommended>
                            <JobTitle>{offer.title}</JobTitle>
                            <JobEmployer>{employer[0].name}</JobEmployer>
                            <JobSalary>{typeOffer}</JobSalary>
                            <JobDescription>
                                {description}...<br/><br/>
                            </JobDescription>
                            <br/>
                            <div className="container" style={{padding: 0}}>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <JobTime>Hace {date} día{(date > 1 ? 's': '')}</JobTime> 
                                    </div>
                                    <div className="col-6" style={{textAlign: "right"}}>
                                        <ButtonShowMore onClick={() => onTrigger(offer.id)} >Ver más</ButtonShowMore>
                                        <ButtonShowMoreRes data-bs-toggle="modal" data-bs-target={"#view-"+ offer.id}>Ver más</ButtonShowMoreRes>
                                    </div>
                                </div>
                                <div class="modal fade" id={"view-"+ offer.id} aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}} >
                                    <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "95%" }}>
                                        <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                            <div class="modal-header">
                                                <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Oferta de trabajo</b></h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                            </div>
                                            <div class="modal-body">
                                                <JobOfferRes
                                                    offer={offer}
                                                    employer={employer[0]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </JobCard>
                </ListItem>
            );
        }
        else
        {
            return (
                <ListItem  key={offer.id} value={offer.id} >
                    <JobCard className="card w-100">
                        <div className="card-body">
                            <JobTitle>{offer.title}</JobTitle>
                            <JobEmployer>{employer[0].name}</JobEmployer>
                            <JobSalary>{typeOffer}</JobSalary>
                            <JobDescription>
                                {description}...<br/><br/>
                            </JobDescription>
                            <div className="container" style={{padding: 0}}>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <JobTime>Hace {date} día{(date > 1 ? 's': '')}</JobTime> 
                                    </div>
                                    <div className="col-6" style={{textAlign: "right"}}>
                                        <ButtonShowMore onClick={() => onTrigger(offer.id)} >Ver más</ButtonShowMore>
                                        <ButtonShowMoreRes data-bs-toggle="modal" data-bs-target={"#view-"+ offer.id}>Ver más</ButtonShowMoreRes>
                                    </div>
                                </div>
                                <div class="modal fade" id={"view-"+ offer.id} aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                                    <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "95%" }}>
                                        <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                            <div class="modal-header">
                                                <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Oferta de trabajo</b></h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                            </div>
                                            <div class="modal-body">
                                                <JobOfferRes
                                                    offer={offer}
                                                    employer={employer[0]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </JobCard>
                </ListItem>
            );
        }
    });
}



export default JobItem;