//import React from "react";
import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
  return `${config["entender-api"].url}${path}`;
};

export const getEmployers = async () => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/employers"), requestOptions);

  const employers = await response.json();

  return employers;
};

export const getEmployerById = async (employerId) => {
  const requestOptions = {
    method: 'GET',
    mode: "cors",
    headers: new Headers({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
        Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/employer/" + employerId), requestOptions);

  return response;
};

export const getEmployerUserData = async (employerId) => {

  const requestOptions = {
    method: 'GET',
    mode: "cors",
    headers: new Headers({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
    }),
  };

  // TODO: Validar que este activo el token

  const response = await fetch(getUrl("/employerUser/" + employerId), requestOptions);

  const employerUser = await response.json();
  return employerUser;
};

export const updateEmployer = async (employerData) => {
  const requestOptions = {
      method: 'POST',
      mode: "cors",
      headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
          Authorization: "Bearer " + cookies.get("accessToken"),
      }),
      body: JSON.stringify(employerData),
  };

  const response = await fetch(getUrl("/updateEmployer"), requestOptions);

  return response;
};

export const deleteEmployerById = async (employerId) => {

  const requestOptions = {
      method: 'DELETE',
      mode: "cors",
      headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
          Authorization: "Bearer " + cookies.get("accessToken"),
      }),
  };

  const response = await fetch(getUrl("/employer/" + employerId), requestOptions);

  return response;
};

