// ----------------------------------- Librarys -----------------------------------
import React, { useState, useEffect } from "react";
import Navbar from "../operatorNavBar";
import StudentsBox from "./studentsBox";
import EmployersBox from "./employersBox";
import JobOffersBox from "./jobOffersBox";
import { createStudentUser } from "../../controllers/users";
import { createEmployerUser } from "../../controllers/employerUser";
// ------------------------------------ Styles ------------------------------------
import {
    AdministrationView,
    JobRender,
    DivContainer,
    TasksHeader,
    ButtonNav,
    Task,
    ListTest,
    ButtonAdd,
} from "./operatorElements";
import { Toast } from "../alert";
import { getInstitutions } from "../../controllers/institutionsAndCareers";
import { Loading } from "../loading";
import { FaIgloo } from "react-icons/fa";
import { getStudentsActive } from "../../controllers/students";
// --------------------------------------------------------------------------------
const Swal = require('sweetalert2');

function OperatorAdministration() {

    const [isLoading, setLoading] = useState(true);
    const [DataCharged, setDataCharged] = useState(false);

    const [state, setState] = useState({
        institutions: []
    });

    const ChangeColor = async (button, tab1, tab2) => {
        document.getElementById(button).setAttribute("active", "true");
        document
            .getElementById(button)
            .setAttribute(
                "style",
                "background: white; color: black;",
                "margin-bottom: 0px;"
            );
        document
            .getElementById(tab1)
            .setAttribute("style", "background: #309E72; color: white; margin-bottom: 0px;");
        document.getElementById(tab1).setAttribute("active", "false");
        document
            .getElementById(tab2)
            .setAttribute("style", "background: #309E72; color: white; margin-bottom: 0px;");
        document.getElementById(tab2).setAttribute("active", "false");
    };

    const studentInvitation = async () => {
        const { value: email } = await Swal.fire({
            title: "Invitar a un nuevo estudiante",
            input: "email",
            inputLabel: "Ingrese el correo del estudiante",
            inputPlaceholder: "Correo electrónico",
            confirmButtonColor: "#309E72",
            validationMessage: "Ingrese un correo válido",  
        });

        if (email) {

            const response = await createStudentUser({ username: email });

            if(response.ok){
                Toast.fire({
                    icon: 'success',
                    title: 'Invitacion enviada!'
                })
            }
            else {
                const res = await response.json();

                Toast.fire({
                    icon: 'error',
                    title: res.message
                })
            }
        }
    };

    const employerInvitation = async () => {
        const { value: email } = await Swal.fire({
            title: "Invitar a un nuevo empleador",
            input: "email",
            inputLabel: "Ingrese el correo del empleador",
            inputPlaceholder: "Correo electrónico",
            confirmButtonColor: "#309E72",
            validationMessage: "Ingrese un correo válido",  
        });

        if (email) {

            const response = await createEmployerUser({ username: email }); // cambiar a employer

            if(response.ok){
                Toast.fire({
                    icon: 'success',
                    title: 'Invitacion enviada!'
                })
            }
            else {
                const res = await response.json();

                Toast.fire({
                    icon: 'error',
                    title: res.message
                })
            }
        }
    };

    const getData = async () => {
        const institutions = await getInstitutions();
        
        setState({...state, institutions: institutions});

        setDataCharged(true);
        
    }

    useEffect(() => {
        if(!DataCharged)
            getData();

        if(DataCharged) 
            setLoading(false);
    });

    const render = () => {
        if (isLoading) return <Loading/>;

        return (
            <div>
                <Navbar />
                <AdministrationView>
                    <div className="row justify-content-center" style={{ height: "90vh" }}>
                        <JobRender>
                            <DivContainer className="card w-100">
                                <TasksHeader>
                                    <div
                                        className="nav nav-tabs"
                                        id="nav-tab"
                                        role="tablist"
                                        style={{
                                            paddingTop: "1em",
                                            paddingInline: "1em",
                                            borderBottom: "0px",
                                        }}
                                    >
                                        <ButtonNav
                                            className="nav-link"
                                            id="students-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#students-task"
                                            type="button"
                                            role="tab"
                                            active="true"
                                            style={{
                                                background: "white",
                                                color: "black",
                                                marginBottom: "0px",
                                            }}
                                            onClick={(e) =>
                                                ChangeColor(e.target.id, "employers-tab", "jobOffers-tab")
                                            }
                                        >
                                            Estudiantes {/* Reemplazar por icono cuando el ancho baje de ??? */}
                                        </ButtonNav>
                                        <ButtonNav
                                            className="nav-link"
                                            id="employers-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#employers-task"
                                            type="button"
                                            role="tab"
                                            style={{ background: "#309E72", color: "white", marginBottom: "0px" }}
                                            onClick={(e) =>
                                                ChangeColor(e.target.id, "students-tab", "jobOffers-tab")
                                            }
                                        >
                                            Empleadores {/* Reemplazar por icono cuando el ancho baje de ??? */}
                                        </ButtonNav>
                                        <ButtonNav
                                            className="nav-link"
                                            id="jobOffers-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#jobOffers-task"
                                            type="button"
                                            role="tab"
                                            style={{ background: "#309E72", color: "white", marginBottom: "0px" }}
                                            onClick={(e) =>
                                                ChangeColor(e.target.id, "students-tab", "employers-tab")
                                            }
                                        >
                                            Ofertas de Trabajo {/* Reemplazar por icono cuando el ancho baje de ??? */}
                                        </ButtonNav>
                                    </div>
                                </TasksHeader>

                                <Task className="card-body">
                                    <div
                                        className="tab-content"
                                        id="nav-tabContent"
                                        style={{ height: "100%" }}
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="students-task"
                                            role="tabpanel"
                                            style={{ height: "100%" }}
                                        >
                                            <ListTest>
                                                <StudentsBox />
                                                <br />
                                                <br />
                                                <br />
                                            </ListTest>
                                            <ButtonAdd
                                                className="btn btn-primary"
                                                onClick={() => studentInvitation()}
                                            >
                                                +
                                            </ButtonAdd>
                                        </div>

                                        <div
                                            className="tab-pane fade"
                                            id="employers-task"
                                            role="tabpanel"
                                            style={{ height: "100%" }}
                                        >
                                            <ListTest>
                                                <EmployersBox />
                                                <br />
                                                <br />
                                                <br />
                                            </ListTest>
                                            <ButtonAdd
                                                className="btn btn-primary"
                                                onClick={() => employerInvitation()}
                                            >
                                                +
                                            </ButtonAdd>
                                        </div>

                                        <div
                                            className="tab-pane fade"
                                            id="jobOffers-task"
                                            role="tabpanel"
                                            style={{ height: "100%" }}
                                        >
                                            <ListTest>
                                                <JobOffersBox Institutions={state.institutions}/>
                                            </ListTest>
                                        </div>
                                    </div>
                                </Task>
                            </DivContainer>
                        </JobRender>
                    </div>
                </AdministrationView>
            </div>
        );
    };

    return render();
}

export default OperatorAdministration;
