// ----------------------------------- Librarys -----------------------------------
import React, { useState, useEffect } from "react";
import { getJobOffers } from "../../controllers/jobOffers";
import { getEmployers } from "../../controllers/employers";
//import EditOffer from "./editOffer"; // EditarEstudiante
// ------------------------------------ Styles ------------------------------------
import { ListItem, Card, Title, Description, IconContainer } from "./operatorElements";
import { MdDeleteOutline } from "react-icons/md";
import { Toast } from "../alert";
import { FiEye } from "react-icons/fi";
import { Loading, OopsLoading } from "../loading";
import { deleteOffer } from "../../controllers/jobOffers";
import ViewOffer from "../HHRR/viewOffer";
// --------------------------------------------------------------------------------

const Swal = require('sweetalert2');

// TODO: Poner delete en cascada para eliminar las postulaciones y guardados en automatico

function JobOffersBox({Institutions}) {

    const [isLoading, setLoading] = useState(true);
    const [jobOffersDataCharged, setJobOffersDataCharged] = useState(false);
    const [state, setState] = useState({
        jobOffersList: [],
    });

    const getJobOffersData = async () => {
        const data = await getJobOffers();
        const employers = await getEmployers();

        let dataAux = [];
        
        data.forEach(jobOffer => {
            
            const employerData = employers.find((employer) => employer.id === jobOffer.employerId);

            if(employerData !== undefined){
                const objectAux = {
                    jobOffer: jobOffer,
                    employerName: employerData.name,
                };

                dataAux.push(objectAux);
            }
        });

        setState({ ...state, jobOffersList: dataAux, });
        setJobOffersDataCharged(true);
    };

    const handleDeleteJobOffer = async (jobOffer) => {

        Swal.fire({
            title: '¿Está seguro de que desea eliminar la oferta de trabajo '+ jobOffer.title +'?',
            text: "¡No se podrán revertir los cambios!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar'
            }).then(async (result) => {

                if (result.isConfirmed) {
                    // Eliminar solicitudes y guardados
                    const response = await deleteOffer(jobOffer.id);

                    if(response.ok){
                        Toast.fire(
                            '¡Eliminado!',
                            'La oferta ' + jobOffer.title + ' ha sido eliminado.',
                            'success'
                        )

                        getJobOffersData();
                    }
                        
                    else
                        Toast.fire(
                            'Error',
                            'La oferta ' + jobOffer.title + ' no ha sido eliminado.',
                            'error'
                        )
                }
        })
    }

    useEffect(() => {
        if (!jobOffersDataCharged)
            getJobOffersData();
        else
            setLoading(false);
    });

    const render = () => {
        if (isLoading) return <Loading />;

        else if (state.jobOffersList[0] === undefined) return <OopsLoading type={"ofertas de trabajo"} />;

        return (state.jobOffersList.map((Object) => {
            return (
                <ListItem key={Object.jobOffer.id}>
                    <Card className="card w-100">
                        <div className="card-body">
                            <div className="container-fluid" style={{ paddingInline: "0" }}>
                                <div className="row">
                                    <div className="col-10">
                                        <Title>{Object.jobOffer.title}</Title>
                                    </div>
                                    <div className="col-2" style={{ textAlign: "right", }}>
                                        <IconContainer>
                                            <FiEye size={24} cursor="pointer" data-bs-toggle="modal" data-bs-target={"#view-"+ Object.jobOffer.id}/>
                                        </IconContainer>
                                        <IconContainer >
                                            <MdDeleteOutline size={30} cursor="pointer" onClick={() => handleDeleteJobOffer(Object.jobOffer)}/>
                                        </IconContainer>
                                    </div>
                                </div>
                            </div>
                            <Description>{Object.employerName}</Description>
                        </div>
                    </Card>

                    <div  class="modal fade" id={"view-"+ Object.jobOffer.id} aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "35%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Oferta de trabajo</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <ViewOffer Offer={Object.jobOffer} Institutions={Institutions}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </ListItem>

            );
        }));
    };

    return render();
};

export default JobOffersBox;