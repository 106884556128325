import React, { Component } from "react";
import {
  LoginRender,
  TopLogo,
  BottomLogo,
  BottomContainer,
} from "./login-registerElements";
import LoginBox from "./loginBox";

function Login() {
  return (
    <LoginRender>
      <div className="container-fluid">
        <BottomContainer>
          <div className="row justify-content-xs-center">
            <div className="col-12 col-xs-12">
              <picture>
                <TopLogo src={require("../../assets/images/TextLogo.png")} />
              </picture>
            </div>
          </div>
        </BottomContainer>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <LoginBox />
          </div>
        </div>
        <BottomContainer>
          <div className="row justify-content-xs-center">
            <div className="col-12 col-xs-12">
              <picture>
                <source
                  media="(max-width: 768px)"
                  srcSet={require("../../assets/images/logos-responsive.png")}
                />
                <BottomLogo src={require("../../assets/images/logos.png")} />
              </picture>
            </div>
          </div>
        </BottomContainer>
      </div>
    </LoginRender>
  );
}

export default Login;
