import {React, useState, useEffect } from 'react';
import styled from 'styled-components';
import JobItem from './jobItem';
import JobOffer from './jobOffer';
import SearchBar from './searchBar';
import Navbar from '../studentNavbar';
import { Loading, OopsLoading, OopsJobLoading} from '../loading';

import {JobsPage} from './jobElements';
import { device } from '../../assets/device';

import {getJobOffers, getJobOffersByDate, getJobOffersByTitle, getJobOfferByEmployerId, getJobOffersBySchedule, getJobOffersByType} from "../../controllers/jobOffers";
import { getEmployers } from '../../controllers/employers';
import Cookies from 'universal-cookie';
import { getApplicationsByJobOffer, getApplicationsByStudent  } from '../../controllers/applications';
import { getStudentById } from "../../controllers/students";
import { getInstitutions } from '../../controllers/institutionsAndCareers';


const cookies = new Cookies();

//Pendiente por trabajar
const ListTest = styled.div`
    height: 78vh;
    overflow-y: scroll;
    overflow-x: hidden;
    

    @media ${device.tablet1}{
        height: 72vh;
    }

    @media (max-width: 390px){
        height: 70vh;
    }
`;

//We start the decision Tree empty as a local var.

let decisionTree;
let neuroCount = 0;
let skillCount = 0;
let maxSkill = [];
let maxNeuro = [];
let recommendedOffer = [];

function Jobs() {
  
  let EmployerState = "";
  let OfferState = "";

  const [state, setState] = useState({
    offers: [],
    offer: "",
    offerId: "",
    employers: [],
    employer: "",
    applications: [],
    studentData: [],
    institutions: [],
  });

  const [dataLoaded, setdataLoaded] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const getAllData = async () => {

    const response = await getStudentById(cookies.get("studentId"));
    const student = await response.json();
    const offersData = await getJobOffers();
    const applicationsData = await getApplicationsByStudent(cookies.get("studentId"));
    const employersData = await getEmployers();
    const institutionsData = await getInstitutions();


    OfferState = offersData[0];

    //We send all offers and student data to a decision Tree algorithm.
    DecisionTree(offersData,student);

    //Gets first employer Data
    for (let i = 0; i < employersData.length; i++) {
      if (recommendedOffer.employerId === employersData[i].id) {
        setState({ 
          ...state,
          employers: employersData,
          employer: employersData[i],
          offers: offersData,
          offer: recommendedOffer,
          offerId: recommendedOffer.id,
          applications: applicationsData,
          studentData: student,
          institutions: institutionsData,
        });
        EmployerState = employersData[i];
      }
    }

    setdataLoaded(true);
  };

  function compareNeuro(offer,student) {

    let count = 0;
    const offerNeuro = offer.neurodiversities.split(",");
    const studentNeuro = student.neurodiversities.split(",");
    
    for (let i = 0; i < offerNeuro.length; i++) {  
        for (let j = 0; j < studentNeuro.length; j++) {
            if (offerNeuro[i] === studentNeuro[j])
                count++;
        }
    }

    if (neuroCount < count) {
        neuroCount = count;
        maxNeuro = offer;
    }

    if (maxNeuro.length === 0)
      maxNeuro = offer;
    
}

function compareSkill(offer, student) {

    let count = 0;
    const offerSkill = offer.skills.split(",");
    const studentSkill = student.skills.split(",");
    
    for (let i = 0; i < offerSkill.length; i++) {  
        for (let j = 0; j < studentSkill.length; j++) {
            if (offerSkill[i] === studentSkill[j])
                count++;
        }
    }

    if (skillCount < count) {
        skillCount = count;
        maxSkill = offer;
    }

    if (maxNeuro.length === 0)
      maxSkill = offer;
    
}

  const SearchTitle = async (title) => {
    let offersData = [];
    if(title.length > 0) {
      offersData = await getJobOffersByTitle(title);
      decisionTree = offersData;
    }
    else
    {
      offersData = await getJobOffers();
      DecisionTree(offersData,state.studentData);
    }

    setState({ 
      ...state,
      offers: offersData,
    });
  }

  const SearchField = async (value,field) => {
    let offersData = [];

    switch(field) {
      case "date":
        offersData = await getJobOffersByDate(value);
        break;
      case "employer":
        offersData = await getJobOfferByEmployerId(value);
        break;
      case "schedule":
        offersData = await getJobOffersBySchedule(value);
        break;
      case "type":
        offersData = await getJobOffersByType(value);
        break; 
    }

    decisionTree = offersData;

    setState({ 
      ...state,
      offers: offersData,
    });
  }

  const SelectedOffer = async (event) => {
    
    const actualOffer = state.offers.filter((offer) => {
      return offer.id === event;
    });

    OfferState = actualOffer[0];

    const actualEmployer = state.employers.filter((employer) => {
      return employer.id === OfferState.employerId;
    });

    EmployerState = actualEmployer[0];
    
    setState({
      ...state,
      offerId: event,
      employer: actualEmployer[0],
      offer: actualOffer[0],
    });

  };

  const DecisionTree = async (offers, student) =>{

    //We create a  local var to storage the final result
    let offersByDecisionTree = [];

    
    offers.map((offer) => {

        if (student.currentGrade >= 10) {

            if (offer.type === "job" || offer.type === "intern") {

                if ( offer.schedule === "FullTime") {

                    compareNeuro(offer, student);
                    compareSkill(offer, student);

                    if(maxNeuro.id === maxSkill.id)
                      recommendedOffer = maxSkill;    
                    else
                      recommendedOffer = maxNeuro;
                }
            }
        }
        else {
            if (offer.type === "practice")
            {
                if ( offer.schedule === "HalfTime")
                {
                    compareNeuro(offer, student);
                    compareSkill(offer, student);

                    if(maxNeuro.id === maxSkill.id)
                      recommendedOffer = maxSkill;    
                    else
                      recommendedOffer = maxNeuro;
                }
            }
        }
        offersByDecisionTree.push(offer);
    });


    //Set recommended offer in the top
    if(offersByDecisionTree.length > 1) {
    offersByDecisionTree = offersByDecisionTree.filter( offer => offer.id !== recommendedOffer.id);
    offersByDecisionTree.unshift(recommendedOffer);
    }
    else if( offersByDecisionTree.length === 1)
    {
      recommendedOffer = offersByDecisionTree[0];
    }

    decisionTree = offersByDecisionTree;
};

  useEffect(() => {

    if (!dataLoaded) getAllData();
    if (dataLoaded) setLoading(false);    
  });

  const render = () => {
    if (isLoading) return <Loading/>;

    return (
        <div>
        <Navbar/>
        <JobsPage>
            <div className="container-fluid" style={{width: '99%'}}>
                <div className="row justify-content-center">
                    <SearchBar employers={state.employers} searchTitle={SearchTitle} searchField={SearchField}/>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-sm-12">
                      <ListTest className="container">
                        {
                          (
                            decisionTree[0] !== undefined ?
                          
                              <JobItem
                                  offers={decisionTree}
                                  employers={state.employers}
                                  selectedOffer={SelectedOffer}
                                  recommendedOffer={recommendedOffer}
                              />
                            :

                            <OopsLoading style={{color:"black"}} type={"ofertas"} />
                          )
                        }
                      </ListTest>
                    </div>
                    <div className="col-lg-7 col-sm-12">
                      {
                        (
                          decisionTree[0] !== undefined ?
                        
                          <JobOffer
                          offer={state.offer}
                          employer={EmployerState ? EmployerState : state.employer}
                          />
                          :

                          <OopsJobLoading style={{color:"black"}} type={"oferta"} />
                        )
                      }
                    
                    </div>
                </div>
            </div>
        </JobsPage>
        </div>
    );
  };

  return render();
}



export default Jobs;