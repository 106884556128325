import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
    return `${config["entender-api"].url}${path}`; // TODO: Aplicarlo para la url v1
};

export const getStudentsActive = async () => {
    const requestOptions = {
        method: "GET",
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
    };

    const response = await fetch(getUrl("/studentsActive"), requestOptions);

    return response;
};

export const getStudentById = async (studentId) => {
    const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
    };

    const response = await fetch(getUrl("/student/" + studentId), requestOptions);

    return response;
};

export const updateStudent = async (studentData) => {
    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
        body: JSON.stringify(studentData),
    };

    const response = await fetch(getUrl("/updateStudent"), requestOptions);

    return response;
};

export const addMediaFile = async (formData, studentId) => {

    const myHeaders = new Headers();

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
    };

    // Missing implementaaation
    // const response = await fetch(getUrl("/app/content/student_picture/" + studentId), requestOptions);

    //return response;
}

export const deleteStudentById = async (studentId) => {
    const requestOptions = {
        method: 'DELETE',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
    };

    const response = await fetch(getUrl("/student/" + studentId), requestOptions);

    return response;
};
