import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
    return `${config["entender-api"].url}${path}`;
};

export const getStudentById = async (studentId) => {

    const requestOptions = {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.get("accessToken"),
      }),
    };
  
    const response = await fetch(getUrl("/student/"+studentId), requestOptions);
    const studentData = await response.json();
  
    return studentData;
  };

export const getStudents = async () => {

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/students"), requestOptions);
  const studentsData = await response.json();

  return studentsData;
};
