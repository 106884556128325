//import React from "react";
import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export let role;

const getUrl = (path) => {
  return `${config["entender-api"].url}${path}`;
};

export const authenticate = async (userData) => {
  let requestOptions = {
    method: "POST",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
    }),
    body: JSON.stringify({ username: userData.username, password: userData.password }),
  };

  const response = await fetch(getUrl("/auth"), requestOptions);

  let content = await response.json();

  if (response.ok && content.accessToken || response.status == 301 && content.accessToken) {
    cookies.set("accessToken", content.accessToken, { path: "/" });
    cookies.set("userId", content.userId, {path: "/"});
  }

  if(content.role === "STUDENT"){
    cookies.set("userId", content.userId, {path: "/"});
    cookies.set("studentId", content.studentId, {path: "/"});
    cookies.set("studentName", content.givenNames, {path: "/"});
  }

   //Change it later for a switch instead!!!!

  if(content.role === "EMPLOYER")
  {
    cookies.set("employerId", content.employerId, {path: "/"});
    cookies.set("employerUserId", content.employerUserId, {path: "/"});
    cookies.set("employerName", content.employerName, {path: "/"});
    cookies.set("employerLogo", content.employerLogoUrl, {path: "/"});
    cookies.set("email", content.email, {path: "/"});
    cookies.set("description", content.description, {path: "/"});
  }

  return {response, content};
};
