import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from 'styled-components';

export const Nav = styled.nav`
    background: #0B5278;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    z-index: 12;
`;

export const NavLogo = styled(Link)`
  cursor: pointer;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;

`;

export const NavLink = styled(Link)`
color: #fff;
font-family: sassoon-sans-std-bold;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&:hover {
  color: #309E72;
}
`;

export const NavLinkRes = styled(Link)`
flex-direction: column;
color: #000;
font-family: sassoon-sans-std-bold;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&:hover {
  color: #06bb70;
}
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: -1.5rem;
    right: -2rem;
    transform: translate(-100%, 75%);
    font-size: 3rem;
    cursor: pointer;
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: transparent;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #309E72;
    color: white;
    border: 1px solid #309E72;
  }
`;

export const ProfileBtnLink = styled(Link)`
  font-family: sassoon-sans-std-bold;
  border-radius: 30px;
  background: transparent;
  padding: .4em 1em .4em .5em;
  color: #fff;
  font-weight: bold;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.3s ease-in-out;
    background: #309E72;
    color: white;
    border: 1px solid #309E72;
  }
`;
