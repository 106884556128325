import React from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import logoImg from "../assets/images/TextLogo.png";
import { LogOff, LogOffRes } from "./logOff";
import {
  Nav,
  NavLogo,
  NavLink,
  NavLinkRes,
  Bars,
  NavMenu,
  NavBtn,
  ProfileBtnLink,
} from "./navbarElements";

const EntenderLogo = styled.div`
  background-image: url(${logoImg});
  background-size: 100%;
  width: 200px;
  height: 40px;
  background-position: center;
`;

const ProfilePicture = styled.img`
  height: 2em;
  border-radius:50%;
  margin-right: 0.5em;
`;

const cookies = new Cookies();

const Navbar = () => {

  const cookieKeys = ["accessToken", "userId", "studentId", "studentName", "institutionName",
  "careerName"];

  return (
    <>
      <Nav>
        <NavLogo to="/student/jobs">
          <EntenderLogo />
        </NavLogo>
        <Bars className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"/>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{background: "#e4e4e4"}}>
            <li><NavLinkRes to="/student/jobs">Jobs</NavLinkRes></li>
            <li><hr className="dropdown-divider"/></li>
            <li><NavLinkRes to="/student/profile">Perfil</NavLinkRes></li>
            <li><hr className="dropdown-divider"/></li>
            <li><LogOffRes CookieKeys={cookieKeys}/></li>
        </ul>

        <NavMenu>
          <NavLink to="/student/jobs" activestyle={{ color: "black",}} style={{paddingRight: "4em"}}>
            Jobs
          </NavLink>
          <NavBtn>
            <ProfileBtnLink to="/student/profile">
              <ProfilePicture src={require('../assets/images/user.png')}/>
                {cookies.get("studentName")}
            </ProfileBtnLink>
            <LogOff CookieKeys={cookieKeys}/>
          </NavBtn>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
