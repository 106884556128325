import React from "react";

import './App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Jobs from "./Views/Student/jobs";
import Login from "./Views/Login/login";
import EmployerProfile from "./Views/HHRR/employerProfile";
import StudentProfile from "./Views/Student/studentProfile";
import StudentRegister from "./Views/Register/studentRegister";
import EmployerRegister from "./Views/Register/employerRegister";
import StudentView from "./Views/University/studentView";
import OperatorAdministration from "./Views/Operator/operatorAdministration";

import {OopsLoading} from "./Views/loading";


function App() {

  //<Navbar/>

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Login/>} />
        <Route exact path="/student/profile" element={<StudentProfile/>}/>
        <Route path="/student/jobs" element={<Jobs/>} />

        <Route path="/employer/profile"  element={<EmployerProfile/>} />
        <Route path="/:studentId" element={<StudentView/>} />

        <Route path="/operator/administration" element={<OperatorAdministration/>} />

        <Route exact path="/studentRegister"  element={<StudentRegister/>} />
        <Route exact path="/employerRegister"  element={<EmployerRegister/>} />

        <Route exact path="/loading" element={<OopsLoading/>} />
    </Routes>
  </Router>
        
      );
}

export default App;