import React, {  useState, useEffect } from "react";
import {ListTest, JobRender, ButtonNav, DivContainer, ProfileImg, ProfileImgContainer, ProfileImgBorder, UsernameLabel, StudentData, StudentDataCard, CardTitle,ProfileView,TasksHeader,Task,CardHeader, ListItem} from '../HHRR/hhrrElements';
import { Loading } from '../loading';

import styled from "styled-components";
import { getEmployerUserData } from "../../controllers/employers";
import { getUserById } from "../../controllers/users";


const EmployerProfileViewFix = styled.div`
    display: flex;
    align-items: center;
    padding: 1em;
`;

function ViewEmployerProfile(Employer) {

    const [isLoading, setLoading] = useState(true);
    const [employersDataCharged, setEmployersDataCharged] = useState(false);
    const [state, setState] = useState({
        employerData: [],
    });

    const getEmployerUser = async() => {
        const data = await getEmployerUserData(Employer.Employer.id);
        const userData = await getUserById(data.userId);
        const response = await userData.json(); 
        setState({...state, employerData: response, });
        setEmployersDataCharged(true);
    };

    useEffect(() => {

        if (!employersDataCharged)
            getEmployerUser();
        else
            setLoading(false);
    });
   

    const render = () => {
        if (isLoading) return <Loading/>;

        return (

            <EmployerProfileViewFix>
                <DivContainer class="card w-100">
                    <CardHeader class="card-header" style={{paddingBottom: "0px"}}>
                        
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <ProfileImgBorder>
                                    <ProfileImgContainer>
                                        <ProfileImg src={require('../../assets/images/user.png')}/>
                                    </ProfileImgContainer>
                                </ProfileImgBorder>
                            </div>

                            <div class="row justify-content-center" style={{paddingTop: "15px"}}>
                                <UsernameLabel style={{marginTop: "0px"}}>
                                    {Employer.Employer.name}
                                </UsernameLabel>
                            </div>

                        </div>
                    </CardHeader>

                    <StudentData>
                        <StudentDataCard class="card">
                            <CardTitle class="card-title">Datos</CardTitle>
                            <label>Descripción: {Employer.Employer.description}</label>
                            <br/>  
                            <label>Correo: {state.employerData.username}</label>
                        </StudentDataCard>
                    </StudentData>

                </DivContainer>
            </EmployerProfileViewFix>

        );
    };

    return render();
};

export default ViewEmployerProfile;