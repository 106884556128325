import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Schedule } from "../../assets/schedule";
import getSkills from "../../controllers/skills";
import { JobType } from "../../assets/jobType";
import {editOffer} from "../../controllers/employerUser";
import { Loading } from '../loading';
import { Toast } from "../alert";
import {RegisterInput, OfferDescription, RegisterBoxRender,RegisterCard, InputLabel, InputLabelMiniBold, RegisterSelector, GreenInput, AboutMe, SubmitButton} from "./hhrrElements";
import { SearchBar, AddSkill, AddNeuro, LabelRed, GreenLabel } from "../Login/login-registerElements";

function SelectorOptions({options}) {
    return(
        options.map(option=>
                <option id={option.id} value={option.value}>
                    {option.name}
                </option>
            )
    );
};

function SelectorOptionsInstitutions({ options }) {
    return (
        options.map(option =>
            <option key={option.id} value={option.id}>
                {option.description}
            </option>
        )
    );
};

function SearchBarSkills({ SkillsList, stateF, setStateF }) {

    let skillsAdded = stateF.skillsAdded;

    function SelectorSkills({ options }) {
        return (
            options.map(option =>
                <div className="col-auto">
                    <GreenLabel id={option + "Id"} value={option} className="btn btn-primary">{option}&emsp;<LabelRed onClick={() => deleteSkill(option)}>X</LabelRed></GreenLabel>
                </div>
            )
        );
    };

    const [isLoading, setLoading] = useState(true);

    const [state, setState] = useState({
        skill: "",
        skills: [],
        getSkillsDataBool: false,
    });

    const getSkillsData = async () => {
        const skillData = SkillsList;
        setState({ ...state, skills: skillData, getSkillsDataBool: true, });
    }

    function formChange(e) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, skill: e.target.value });
    }

    function addSkill() {
        if (!skillsAdded.find((skill) => skill === state.skill)) {
            if(state.skill !== ""){
                skillsAdded.push(state.skill);
                setStateF({ ...stateF, skillsAdded: skillsAdded });
            }
        }
    }

    function deleteSkill(option) {
        skillsAdded = skillsAdded.filter(skill => skill !== option);
        setStateF({ ...stateF, skillsAdded: skillsAdded });
    }

    useEffect(() => {
        if (!state.getSkillsDataBool) getSkillsData();

        if (state.skills[0] !== "") { setLoading(false); };
    });

    const render = () => {

        if (isLoading) return <Loading />;

        return (
            <div className='searchBar'>
                <div className='searchInputs' >
                    <SearchBar className="mdb-select md-form" style={{boxShadow: "none"}} searchable="Busca tus habilidades..." value={state.skill} onChange={(event) => formChange(event)}>
                        <option value="" disabled selected>Busca tus habilidades...</option>
                        <SelectorOptions options={state.skills} />
                    </SearchBar>
                    <AddSkill type="button" className="btn btn-primary" style={{marginLeft: "1em"}} onClick={() => addSkill()}>+</AddSkill> { /* TODO: Agregar el metodo para anadir una nueva skill a la bd Y cambiar nombre*/ }
                </div> <br />
                <div class='row'>
                    <SelectorSkills options={skillsAdded} />
                </div>
            </div>
        );
    };

    return render();
};

function SearchBarNeurodiversities({ NeurodiversitiesList, stateF, setStateF }) {

    let neurosAdded = stateF.neurosAdded;

    function SelectorNeuros({ options }) {
        return (
            options.map(option =>
                <div className="col-auto">
                    <GreenLabel id={option + "Id"} value={option} className="btn btn-primary">{option}&emsp;<LabelRed onClick={() => deleteNeuro(option)}>X</LabelRed></GreenLabel>
                </div>
            )
        );
    };

    const [isLoading, setLoading] = useState(true);

    const [state, setState] = useState({
        neuro: "",
        neuros: [],
        getNeurosDataBool: false,
    });

    const getNeurosData = async () => {
        const neuroData = NeurodiversitiesList;
        setState({ ...state, neuros: neuroData, getNeurosDataBool: true, });
    }

    function formChange(e) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, neuro: e.target.value });
    }

    function addNeuro() {
        if (!neurosAdded.find((neuro) => neuro === state.neuro)) {
            if(state.neuro !== ""){
                neurosAdded.push(state.neuro);
                setStateF({ ...stateF, neurosAdded: neurosAdded });
            }
        }
    }

    function deleteNeuro(option) {
        neurosAdded = neurosAdded.filter(neuro => neuro !== option);
        setStateF({ ...stateF, neurosAdded: neurosAdded });
    }

    useEffect(() => {
        if (!state.getNeurosDataBool) getNeurosData();

        if (state.neuros[0] !== "") { setLoading(false); };
    });

    const render = () => {

        if (isLoading) return <Loading />;

        return (
            <div className='searchBar'>
                <div className='searchInputs' >
                    <SearchBar className="mdb-select md-form" style={{boxShadow: "none"}} searchable="Busca las neurodiversidades..." value={state.neuro} onChange={(event) => formChange(event)}>
                        <option value="" disabled selected>Busca las neurodiversidades...</option>
                        <SelectorOptions options={state.neuros} />
                    </SearchBar>
                    <AddNeuro type="button" className="btn btn-primary" style={{marginLeft: "1em"}} onClick={() => addNeuro()}>+</AddNeuro> { /* TODO: Agregar el metodo para anadir una nueva skill a la bd Y cambiar nombre*/ }
                </div> <br />
                <div class='row'>
                    <SelectorNeuros options={neurosAdded} />
                </div>
            </div>
        );
    };

    return render();
};

function OfferBox({ Offer, SkillsList, InstitutionsList, NeurodiversitiesList }) {

    let skillsAux = Offer.skills.split(',');
    let neurosAux = Offer.neurodiversities.split(',');

    const [state, setState] = useState({
        skillsAdded: skillsAux,
        neurosAdded: neurosAux,
        ...Offer
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        // TODO: Cambiar por edit offer
        const response = await editOffer({jobOfferId: Offer.id, title: state.title, skills: state.skillsAdded.toString(), description: state.description, neurodiversities: state.neurosAdded.toString(), type: state.type, schedule: state.schedule, institutionId: parseInt(state.institutionId)});

        if (response.ok) {
            Toast.fire({
                icon: "success",
                title: "Postulación guardada!",
            });
        }
        else {
            Toast.fire({
                icon: "error",
                title: "Error", // ? Regresar otro mensaje
            });
        }

        document.location.reload();
    };

    function formChange( e, field ) {
        //We add a "...state" before to get old value and then update the required field
        setState({ ...state, [field]: e.target.value });
    }

    return(
        <RegisterBoxRender class="container">
            <RegisterCard class="card">
                <div class="card-body">
                    <form class="was-validated" onSubmit={handleSubmit}>
                        <div class="row">
                            <div class="col-12">
                                <InputLabel  class="form-label">Título</InputLabel>
                                <br/>
                                <RegisterInput id="job-title" type="text" placeholder="Título" required value={state.title} onChange={(event) => formChange(event, "title")}/>
                                <div class="invalid-feedback">*Campo requerido</div>
                                <br/>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-6">
                                <InputLabel  class="form-label">Tipo de empleo</InputLabel>
                                <br/> 
                                <RegisterSelector class="form-select" id="job-type" value={state.type} onChange={(event) => formChange(event, "type")}>
                                    <SelectorOptions options={JobType} /> 
                                </RegisterSelector>
                                <br/>
                                <br/>
                            </div>
                            <div class="col-6">
                                <InputLabel  class="form-label">Horario</InputLabel>
                                <br/> 
                                <RegisterSelector class="form-select" id="job-schedule" value={state.schedule} onChange={(event) => formChange(event, "schedule")}>
                                    <SelectorOptions options={Schedule}/> 
                                </RegisterSelector>
                                <br/>
                                <br/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <InputLabel className="form-label">Centro Universitario</InputLabel>
                                <RegisterSelector class="form-select" value={state.institutionId} onChange={(e) => setState({ ...state, institutionId: e.target.value })} style={{textAlign: "center"}} required>
                                    <option value="" disabled selected>Selecciona tu centro universitario</option>
                                    <SelectorOptionsInstitutions style={{textAlign: "left"}} options={InstitutionsList} />
                                </RegisterSelector>
                                <div class="invalid-feedback">*Campo requerido</div>
                                <br />
                            </div>
                        </div>
                        <br/>

                        <InputLabel  class="form-label">Habilidades</InputLabel>
                        <div class="row">
                            <SearchBarSkills SkillsList={SkillsList} stateF={state} setStateF={setState}/>
                        </div>
                        <br/>

                        <InputLabel  class="form-label">Neurodiversidades</InputLabel>
                        <div class="row">
                            <SearchBarNeurodiversities NeurodiversitiesList={NeurodiversitiesList} stateF={state} setStateF={setState}/>
                        </div>
                        <br/>
                    
                        <div class="row">
                            <div class="col-12">
                                <InputLabel  class="form-label">Descripción</InputLabel>
                                <br/>
                                <OfferDescription id="job-description" placeholder="Agrega la información de la vacante" value={state.description} onChange={(event) => formChange(event, "description")}/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </div>  

                        <div class="row justify-content-center">
                            <div class="col-12" style={{textAlign: "center"}}> 
                                <SubmitButton type="submit" class="btn btn-primary">Guardar</SubmitButton>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </form>
                </div>
            </RegisterCard>
        </RegisterBoxRender>
    );
};

export default OfferBox;