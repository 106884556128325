import config from "../config/default.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
  return `${config["entender-api"].url}${path}`;
};

export const getSkills = async () => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/skills"), requestOptions);
  const skills = await response.json();
  
  return skills;
};

export const addSkill = async (skill) => {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
    body: JSON.stringify(skill),
  };

  const response = await fetch(getUrl("/add-skill"), requestOptions);
  
  return response;
};
