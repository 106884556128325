import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { FaAngleDown, FaSearch, FaBriefcase, FaBusinessTime } from 'react-icons/fa';
import { BsCalendarDate } from 'react-icons/bs';
import { IoMdBusiness } from 'react-icons/io';

import { SearchContainer, SearchTable, SearchInput, SearchButton, SearchButtonRes, DropdownContainers, DarkBlueButton, FilterButton, FilterButtonRes } from './jobElements';



function SearchBar({employers, searchTitle, searchField}) {

    const [state, setState] = useState({
        search: "",
        date : "",
        employer: "",
        type:"",
        schedule: "",
    });

    function DropdownOptions({ options }) {
        return options.map((option) => (
            <li key={option.id}>
                <button value={option.id} className="dropdown-item" onClick={(e) => changeSearch(e,"employer")}>{option.name}</button>
            </li>
        ));
    }

    function changeSearch(e,field) {
        setState({ ...state, [field]: e.target.value });
        if(field !== "search")
            searchField(e.target.value,field);
    }

    return (
        <SearchContainer>
            <SearchTable className="container-fluid">
                <div className="row" style={{textAlign: "center", marginRight: '1em'}}>
                    <div className="col-md-5 col-sm-12" style={{padding: 0}}>
                        <SearchInput type="text" className="form-control" placeholder="" aria-label="searchBar" aria-describedby="searchInput" value={state.search} onChange={ e => changeSearch(e,"search")}/>
                        <SearchButton className="btn btn-primary" type="button" onClick={() => searchTitle(state.search)}>Buscar</SearchButton>
                        <SearchButtonRes onClick={() => searchTitle(state.search)}><FaSearch/></SearchButtonRes>
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <div className="btn-group">

                            <DropdownContainers className="dropdown" >
                                <FilterButton className="btn btn-primary dropdown-toggle" type="button" id="searchByDate" data-bs-toggle="dropdown" aria-expanded="false">
                                    Fecha de Publicación
                                </FilterButton >
                                <FilterButtonRes className="btn btn-primary dropdown-toggle" type="button" id="searchByDate" data-bs-toggle="dropdown" aria-expanded="false">
                                    <BsCalendarDate/>
                                </FilterButtonRes>
                                <ul className="dropdown-menu" aria-labelledby="searchByDate">
                                    <li><button className="btn btn-secondary dropdown-item" value="1" onClick={(e) => changeSearch(e,"date")} >Últimas 24 horas</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item" value="7" onClick={(e) => changeSearch(e,"date")}>Últimos 7 días</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item" value="30" onClick={(e) => changeSearch(e,"date")}>Últimos 30 días</button></li>
                                </ul>
                            </DropdownContainers>

                            <DropdownContainers className="dropdown">
                                <FilterButton className="btn btn-primary dropdown-toggle" type="button" id="searchByEmployer" data-bs-toggle="dropdown" aria-expanded="false" >
                                    Empresa
                                </FilterButton>
                                <FilterButtonRes className="btn btn-primary dropdown-toggle" type="button" id="searchByEmployer" data-bs-toggle="dropdown" aria-expanded="false">
                                    <IoMdBusiness/>
                                </FilterButtonRes>
                                <ul className="dropdown-menu" aria-labelledby="searchByEmployer">
                                    <DropdownOptions options={employers}/>
                                </ul>
                            </DropdownContainers>

                            <DropdownContainers className="dropdown">
                                <FilterButton className="btn btn-primary dropdown-toggle" type="button" id="searchBySchedule" data-bs-toggle="dropdown" aria-expanded="false">
                                    Horario
                                </FilterButton>
                                <FilterButtonRes className="btn btn-primary dropdown-toggle" type="button" id="searchBySchedule" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FaBusinessTime/>
                                </FilterButtonRes>
                                <ul className="dropdown-menu" aria-labelledby="searchBySchedule">
                                    <li><button className="btn btn-secondary dropdown-item" value={"HalfTime"} onClick={(e) => changeSearch(e,"schedule")}>Medio tiempo</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item" value={"FullTime"} onClick={(e) => changeSearch(e,"schedule")}>Tiempo completo</button></li>
                                </ul>
                            </DropdownContainers>

                            <DropdownContainers className="dropdown">
                                <FilterButton className="btn btn-primary dropdown-toggle" type="button" id="searchByType" data-bs-toggle="dropdown" aria-expanded="false">
                                    Modalidad
                                </FilterButton>
                                <FilterButtonRes className="btn btn-primary dropdown-toggle" type="button" id="searchByType" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FaBriefcase/>
                                </FilterButtonRes>
                                <ul className="dropdown-menu" aria-labelledby="searchByType">
                                    <li><button className="btn btn-secondary dropdown-item" value={"practice"} onClick={(e) => changeSearch(e,"type")}>Prácticas</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item" value={"intern"} onClick={(e) => changeSearch(e,"type")}>Becario</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item" value={"job"} onClick={(e) => changeSearch(e,"type")}>Empleo</button></li>
                                </ul>
                            </DropdownContainers>
                        </div>
                    </div>
                </div>
            </SearchTable>
        </SearchContainer>
    );
};



export default SearchBar;