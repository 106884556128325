import React, { useState, useEffect } from "react";
import StudentRegisterBox from "./studentRegisterBox";
import { LoginImg, TopContainer, BottomContainer, BottomLogo, RegisterRender} from "../Login/login-registerElements";
import getNeurodiversities from "../../controllers/neurodiversities";
import { Loading } from '../loading';

function StudentRegister() {

    const [isLoading, setLoading] = useState(true);
    const [neuroDataCharged, setNeuroDataCharged] = useState(false);
    const [state, setState] = useState({
        //skills: [],
        neuros: [],
    });

    const getAllData = async () => {
        //const skillData = await getSkills();
        const neuroData = await getNeurodiversities();
        setState({...state, neuros: neuroData,});
        setNeuroDataCharged(true);
    }

    useEffect(() => {
        if(!neuroDataCharged)
            getAllData();

        if(neuroDataCharged) 
            setLoading(false);
    });

    const render = () => {
        if (isLoading) return <Loading/>;

        return (
            <RegisterRender>
                <div className="container-fluid">
                    <TopContainer className="row justify-content-center">
                        <div className="col col-lg-12">
                            <LoginImg src={require('../../assets/images/TextLogo.png')}/>
                        </div>
                    </TopContainer>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <StudentRegisterBox NeuroList={state.neuros}/>
                        </div>
                    </div>
                    <BottomContainer className="container">
                        <div className="row justify-content-xs-center">
                            <div className="col-12 col-xs-12">
                                <picture>
                                    <source media="(max-width: 768px)" srcSet={require('../../assets/images/logos-responsive.png')}/>  
                                    <BottomLogo src={require('../../assets/images/logos.png')}/>
                                </picture>
                            </div>
                        </div>
                    </BottomContainer>
                </div>
            </RegisterRender>
        );
    }

    return render();
};

export default StudentRegister;