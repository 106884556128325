import config from "../config/default.json";
import Cookies from "universal-cookie"; 

const cookies = new Cookies();

const getUrl = (path) => {
    return `${config["entender-api"].url}${path}`;
};

export const createEmployerUser = async ({ username }) => {
    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${config["entender-api"].url}`,
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
        body: JSON.stringify({ username: username }),
    };

    const response = await fetch(getUrl("/createEmployerUser"), requestOptions);

    return response;
}

export const addOffer = async ({title, skills, description, neurodiversities, type, schedule, institutionId}) => {

    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
        body: JSON.stringify({ title: title, skills: skills, description: description, employerId: cookies.get("employerId"), neurodiversities: neurodiversities, type: type, schedule: schedule, institutionId: institutionId }),
    };

    const response = await fetch(getUrl("/add-offer"), requestOptions);

    return response;
}

export const editOffer = async ({jobOfferId, title, skills, description, neurodiversities, type, schedule, institutionId}) => {

    if(skills[0] === ",")
        skills = skills.substring(1);

    if(neurodiversities[0] === ",")
        neurodiversities = neurodiversities.substring(1);
    
    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("accessToken"),
        }),
        body: JSON.stringify({ title: title, skills: skills, description: description, employerId: cookies.get("employerId"), neurodiversities: neurodiversities, type: type, schedule: schedule, institutionId }),
    };

    const response = await fetch(getUrl("/job-offer/"+jobOfferId), requestOptions);

    return response;
}

export const getMyApplications = async (employerId) => {

    const requestOptions = {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.get("accessToken"),
      }),
    };
  
    const response = await fetch(getUrl("/job-applications/findByEmployerId/"+employerId), requestOptions);
    const application = await response.json();
  
    return application;
  };
