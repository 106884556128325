import { React, useState, useEffect } from "react";
import Navbar from "../studentNavbar";
import { ListTest, ProfileRender, ButtonNav, DivContainer, ProfileImg, ProfileImgContainer, ProfileImgBorder, UsernameLabel, StudentData, StudentDataCard, CardTitle, ProfileView, TasksHeader, Task, CardHeader, GreenInput, BlueInput } from "./jobElements";

import { getStudentById } from "../../controllers/students";
import Cookies from "universal-cookie";
import { Loading } from '../loading';

import MyApplications from "./myApplications";
import { getJobOffers } from "../../controllers/jobOffers";
import { getEmployers } from "../../controllers/employers";
import getSkills from "../../controllers/skills";
import getNeurodiversities from "../../controllers/neurodiversities";
import MySaves from "./mySaves";

const cookies = new Cookies();

function SelectorSkills({options}) {
  return(
      options.map(option=>
              <div class="col-auto">
                  <BlueInput id={option.id} class="btn" style={{cursor: "default"}}>{option}</BlueInput>
              </div>
          )
  );
};

function SelectorNeuro({options}) {
  return(
      options.map(option=>
              <div class="col-auto">
                  <input type="checkbox" class="btn-check" id={option.id} value={option.name}autocomplete="off"/>
                  <BlueInput id={option.id} class="btn" style={{cursor: "default"}} for={option.id}>{option}</BlueInput>
              </div>
          )
  );
};

function getAge(dateString) 
{
    var ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds/1000/60/60/24/365); // convert to years
}

function StudentProfile() {

  const institutionName = cookies.get("institutionName");
  const careerName = cookies.get("careerName");

  const [isLoading, setLoading] = useState(true);
  const [studentDataCharged, setStudentDataCharged] = useState(false);

  const [state, setState] = useState({
    student: [],
    offers: [],
    employers: [],
    skills: [],
    neuros: [],
    institutionName: institutionName,
    careerName: careerName,
  });

   //Loads all data 
  const getStudentData = async() => {

    const response = await getStudentById(cookies.get("studentId"));
    const student = await response.json();

    const offers = await getJobOffers();
    const employers = await getEmployers();
    const skills = student.skills.split(',');
    const neuros = student.neurodiversities.split(',');

    setState({...state, student: student, offers: offers, employers: employers, skills: skills, neuros: neuros});
    setStudentDataCharged(true);
  };

  const ChangeColor = async (button, tab1) => {

    document.getElementById(button).setAttribute("active","true");
    document.getElementById(button).setAttribute("style","background: white; color: black");
    document.getElementById(tab1).setAttribute("style","background: #309E72; color: white");
    document.getElementById(tab1).setAttribute("active","false");
  };

  useEffect(() => {
    if(!studentDataCharged)
      getStudentData();

    if (studentDataCharged)
      setLoading(false);
  });

  const render = () => {
    if (isLoading) return <Loading/>;

    return (
      <div>
        <Navbar />
        <ProfileView>
          <div class="row justify-content-center">
            <div class="col-md-5">
              <ProfileRender>
                <DivContainer className="card w-100">
                  <CardHeader>
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <ProfileImgBorder>
                          <ProfileImgContainer>
                            <ProfileImg
                              src={require("../../assets/images/user.png")}
                            />
                          </ProfileImgContainer>
                        </ProfileImgBorder>
                      </div>
                    </div>
                  </CardHeader>
                  <div className="row justify-content-center">
                    <UsernameLabel>{state.student.givenNames} {state.student.familyNames}</UsernameLabel>
                  </div>
                    <ListTest>
                      <StudentData>
                        <StudentDataCard className="card">
                          <CardTitle className="card-title">
                            Datos Personales
                          </CardTitle>
                          <label>Universidad: {state.institutionName} CUCEI </label>
                          <label>Carrera: {state.careerName} Ingeniería Informática </label>
                          <label>Edad: {getAge(state.student.dateOfBirth)} años</label>
                          <label>Actualidad: Cursando {state.student.currentGrade}° semestre</label>
                        </StudentDataCard>
                        <StudentDataCard class="card">
                            <CardTitle class="card-title">Acerca de mí</CardTitle>
                            <p>{state.student.selfDescription}
                            Soy un estudiante en su último semestre de la carrera. Me apasiona investigar sobre todo lo que pueda, así
                            como aprender cosas nuevas todos los días. Me gusta el cine, la programación y hacer ejercicio.
                            </p>
                            <div class="row">
                              <CardTitle class="card-title">Habilidades</CardTitle>
                                <SelectorSkills options={state.skills}/>
                            </div>
                            <div class="row">
                              <CardTitle class="card-title">Condición(es)</CardTitle>
                                <SelectorNeuro options={state.neuros}/>
                            </div>
                        </StudentDataCard>

                        <StudentDataCard className="card">
                          <CardTitle className="card-title">Contacto</CardTitle>
                          <label>Teléfono: {state.student.phoneNumber}</label>
                          <label>Correo: {state.student.email}</label>
                        </StudentDataCard>
                      </StudentData>
                  </ListTest>
                </DivContainer>
              </ProfileRender>
            </div>
            <div class="col-md-7" style={{ height: "100%" }}>
              <ProfileRender>

                <DivContainer class="card w-100">

                  <TasksHeader>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist" style={{ paddingTop: "1em", paddingInline: "1em", borderBottom: "0px", }} >
                      <ButtonNav class="nav-link" id="myApplications-tab" data-bs-toggle="tab" data-bs-target="#myApplications-task" type="button" role="tab" active="true" style={{background: "white", color: "black"}} onClick={(e) => ChangeColor(e.target.id, "mySaves-tab")} > Postulaciones </ButtonNav>
                      <ButtonNav class="nav-link" id="mySaves-tab" data-bs-toggle="tab" data-bs-target="#mySaves-task" type="button" role="tab" onClick={(e) => ChangeColor(e.target.id, "myApplications-tab")} > Guardados </ButtonNav>
                    </div>
                  </TasksHeader>

                  <Task class="card-body" style={{marginTop: "15px", overflowY: "scroll"}}>
                    <div class="tab-content" id="nav-tabContent" style={{ height: "100%" }}>
                      
                      <div class="tab-pane fade show active" id="myApplications-task" role="tabpanel" aria-labelledby="my Applications" style={{ height: "100%" }}>
                        <MyApplications Offers = {state.offers} Employers = {state.employers}/>
                      </div>

                      <div class="tab-pane fade" id="mySaves-task" role="tabpanel" aria-labelledby="my Saves" style={{ height: "100%" }}>
                        <MySaves Offers = {state.offers} Employers = {state.employers}/>
                      </div>

                    </div>
                  </Task>

                </DivContainer>
              
              </ProfileRender>
            </div>
          </div>
        </ProfileView>
      </div>
    );
  };

  return render();
}
export default StudentProfile;
