import styled from 'styled-components';
import { device } from '../../assets/device';

export const AdministrationView = styled.div`
    width: 100%;
    heigth: 90vh;
    padding: .6em 1em;
`;

export const JobRender = styled.div`
    display: flex;
    align-items: center;
    padding: 1em 1em;
    height: 100%;
`;

export const DivContainer = styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    height: 100%;
    width: 100%;
    padding: 0;
`;

export const TasksHeader = styled.nav`
    background: #0B5278; 
    border-radius:15px 15px 0 0;
`;

export const ButtonNav = styled.div`
    background: #309E72;
    border-radius: 15px 15px 0 0;
    width: 20%;
    padding: .5em 1em;
    font-size: 1em;
    font-family: sassoon-sans-std-bold;
    color: white;
    text-align: center;
    border-bottom: "0px";

    &:active {
        transition: all 0.3s ease-in-out;
        background: white;
        color: black;
        border-bottom: "0px";
    }
`;

export const Task = styled.div`
    padding: 0em;
    height: 90%;
`;

export const ListTest = styled.div`
    height: 97%;
    margin-top: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const ListTestButtonAdd = styled.div`
    height: 97%;
    margin-top: 1em;
    margin-bootom: 0em;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const ListItem = styled.div`
    align-items: center;
    background: white;
    padding: 1em 1em;
`;

export const Card= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    padding: 0.3em;
`;

export const Title = styled.h3`
    font-size: 2.5em;
    margin-bottom: 0px;
    font-family: sassoon-sans-std-bold;
`;

export const Description = styled.h5`
    font-size: 1.3em;
    margin-top: 1rem;
    font-family: sassoon-sans-std;
`;

export const IconContainer = styled.a`
    margin-left: 0.5em;
    color: #309E72;
    &:hover{
        color: #0B5278;
    }
`;

export const ButtonAdd = styled.button`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1.5em;
    color: white;
    border: solid 1px #309E72;
    border-radius: 30px;
    width: 2em;
    position: relative;
    float: right;
    margin-right: 2em;
    margin-top: -2.7em;
    text-align: center;
`;