import styled from 'styled-components';
import { device, deviceMin } from '../../assets/device';

export const ListItem = styled.div`
    display: flex;
    align-items: center;
    background: white;
    padding: 1em;
`;

export const JobItem= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    height: 100%;
    padding: 0;
`;

export const JobCard= styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    padding: 0.3em;
    width: 100%;
`;

export const Recommended = styled.p`
    color: RoyalBlue;
    font-size: .9em;
    font-family: sassoon-sans-std-bold;
`;

export const JobTitle = styled.h3`
    font-size: 2.5em;
    margin-bottom: 0px;
    font-family: sassoon-sans-std-bold;

    @media (max-width: 550px) {
        font-size: 2em;
    }
`;

export const JobEmployer = styled.h5`
    font-size: 1.3em;
    margin-bottom: 1.5rem;
    font-family: sassoon-sans-std;
`;

export const JobSalary = styled.h6`
    font-size: 1.2em;
    font-family: sassoon-sans-std-bold;
    margin-bottom: 1rem;
`;

export const JobTime = styled.p`
    font-size: 1em;
    color: Gray;
    margin-bottom:0px;
    width: auto;
    font-family: sassoon-sans-std;
    float: left;
`;

export const JobsPage = styled.div`
    display: flex;
    background: white;
`;

export const VerticalLine = styled.div`
    border-left: 5px solid #309E72;
    display: flex;
    align-items: center;
    background: white;
    padding: 1em 1em;
    height: 1000px;
    width: 0%;
`;

export const JobRender = styled.div`
    display: flex;
    align-items: center;
    padding: 1em 1em;
    height: 78vh;

    @media (max-width: 991px) {
        display: none;
    }
`;

export const JobRenderRes = styled.div`
    display: flex;
    align-items: center;
    padding: 1em 1em;
    height: 78vh;
`;

export const JobDescription = styled.div`
    position: relative;
    :after {
        position: absolute;
        bottom: 0;
        right: 0%; 
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80% 
        );
`;

export const SearchContainer = styled.div`
    color: black;
    margin 1rem;
    border-bottom: 1px solid #309E72;
`;

export const SearchTable = styled.div`
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

export const SearchInput = styled.input`
    padding: 8px;
    font-family: sassoon-sans-std;
    background: white;
    border: solid 1px silver;
    border-radius:10px;
    width:80%;
    :focus {
        outline: solid 1px #309E72 !important;
    }
`;

export const GreenButton = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const GreenButtonRes = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;

    @media (min-width: 768px) {
        display: none;
    }
`;

export const GreenButtonStatic = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
`;

export const ButtonShowMore = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;

    @media (max-width: 991px) {
        display: none;
    }
`;

export const ButtonShowMoreRes = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;

    @media (min-width: 991px) {
        display: none;
    }
`;

export const SearchButton = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    float: right;      // TODO: Por??? 
    margin-top: -42px; // ? Agregado

    @media ${device.laptopM}{
        display: none;
    }
`;

export const SearchButtonRes = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    float: right;
    margin-top: -42px; // ? Agregado 

    @media ${deviceMin.laptopM1}{
        display: none;
    }
`;

export const LightBlueButton = styled.button`
    background: #5B6EFF;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #5B6EFF;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;
`;

export const DarkBlueButton = styled.button`
    background: #0B5278;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #0B5278;
`;

export const FilterButton = styled.button`
    background: #0B5278;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #0B5278;

    @media ${device.laptopM}{
        display: none;
    }
`;

export const FilterButtonRes = styled.button`
    background: #0B5278;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #0B5278;

    @media ${deviceMin.laptopM1}{
        display: none;
    }
`;

export const WhiteButton = styled.button`
    background: white;
    font-size: 1em;
    color: black;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px white;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;
    &:hover{
        background: #E3E3E3;
        border: solid 1px #E3E3E3;
    }
`;

export const RedButton = styled.button`
    background: #D33838;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #D33838;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;
    &:hover{
        background: #BD2C2C;
        border: solid 1px #BD2C2C;
    }
`;

export const WhiteButtonRes = styled.button`
    background: white;
    font-size: 1em;
    color: black;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px white;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;
    &:hover{
        background: #E3E3E3;
        border: solid 1px #E3E3E3;
    }

    @media (max-width: 550px) {
        font-size: .7em;
    }
`;

export const RedButtonRes = styled.button`
    background: #D33838;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #D33838;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;
    &:hover{
        background: #BD2C2C;
        border: solid 1px #BD2C2C;
    }

    @media (max-width: 550px) {
        font-size: .7em;
    }
`;

export const SaveButtonErase = styled.button`
    background: #309E72;
    font-size: 1em;
    color: #D33838;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
`;

export const SaveButton = styled.button`
    background: #309E72;
    font-size: 1em;
    color: white;
    border-radius: 10px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
`;

export const DropdownContainer = styled.div`
    textAlign: "center";
`;

export const DropdownContainers = styled.div`
    padding: 0px 0.8em;

    @media ${device.tablet1}{
        margin-top: 1em;
        padding: 0px 0.3em;
    }
`;

export const JobHeader = styled.div`
    background-color: #0B5278;
    border-radius: 14px 14px 0 0;
    color: white;
    border-bottom: 1px solid #0B5278;
    box-shadow: 0 8px 8px -4px Silver;
    height: 205px;
`;

export const JobDetails = styled.div`
    font-size: 1em;
    font-family: sassoon-sans-std;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`;

export const JobInfo = styled.div`
    overflow-y: scroll;
    overfloy-x: hidden;
    padding: 0 3em 0 3em;
    margin: 2.5em 0 2.5em 0;
    height: 45vh;
    border-radius: 0 0 14px 14px;
    font-family: sassoon-sans-std;
`;

export const JobImgContainer = styled.div`
    background: #309E72;
    border-radius: 0 0 50% 50%;
    color: white;
    height: 10em;
    width: 10em;
    margin: 0 0 0 20%;

    @media ${device.laptopM}{
        margin: 0 0 0 5%;
    };
    @media (max-width: 1145px){
        margin: 0;
    };

    display: flex;
    justify-content: center;
`;

export const JobImgContainerRes = styled.div`
    background: #309E72;
    border-radius: 0 0 50% 50%;
    color: white;
    height: 10em;
    width: 10em;
    margin-top: -1.3em;
    position: absolute;
    right: 50px;

    @media (max-width: 740px) {
        height: 8em;
        width: 8em;
        right: 25px;
    }

    @media (max-width: 550px) {
        height: 7em;
        width: 7em;
        right: 15px;
    }

    @media (max-width: 480px) {
        display: none;
    }
`;

export const JobImg = styled.img`
    width: auto;
    height:80%;
    margin-top:15px;    
`;

export const JobImgRes = styled.img`
    width: auto;
    height:80%;
    margin-top:15px; 
    margin-left:16px;  
    
    @media (max-width: 740px) {
        margin-top:12px; 
        margin-left:13px; 
    }

    @media (max-width: 550px) {
        margin-top:10.5px; 
        margin-left:11.5px; 
    }
`;

export const JobPrincipalInfo = styled.div`
    width: 100%;
    padding: 1.3em; 
    margin-left: 10px;
`;

export const JobPrincipalInfoRes = styled.div`
    width: 65%;
    padding: 1.3em; 
    margin-left: 10px;

    @media (max-width: 480px) {
        width: 100%;
    }
`;


export const ListTest = styled.div`
    height:100%;
    margin-bottom: 1.5em;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const ButtonNav = styled.div`
    background: #309E72;
    border-radius: 15px 15px 0 0;
    padding: .5em 1em;
    font-size: 1em;
    font-family: sassoon-sans-std-bold;
    color: white;
    text-align: center;
    border-bottom: "0px";

    &:active {
        transition: all 0.3s ease-in-out;
        background: white;
        color: black;
        border-bottom: "0px";
    }
`;

export const DivContainer = styled.div`
    background: white;
    border-radius: 15px;
    border: 1px solid Silver;
    box-shadow: 0 8px 8px -4px lightGray;
    height: 100%;
    width: 100%;
    padding: 0;
`;

export const ProfileRender = styled.div`
    display: flex;
    align-items: center;
    padding: 1em;
    height: 90vh;
`;

export const ApplicationRender = styled.div`
    display: flex;
    align-items: center;
    padding: 1em;
    height: 90vh;
`;

export const ProfileImg= styled.img`
    height: 92%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export const ProfileImgContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    
`;

export const ProfileImgBorder = styled.div`
    background: #309E72;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
`;

export const UsernameLabel = styled.label`
    text-align: center;
    font-family: sassoon-sans-std-bold;
    margin: 1em;
    background: white;
    color: black;
    padding: 1.2em 1.5em;
    width: auto;
    line-height: 0em;
    border-radius: 15px;
    box-shadow: 0 8px 8px -5px black;
    margin-top: -25px;
`;


export const StudentData = styled.div`
    padding:  1.5em;
    background: white;
    height: 70%;   
    border-radius: 0 0 14px 14px;
    font-family: sassoon-sans-std;
`;

export const StudentDataCard = styled.div`
background: white;
width: 100%;
padding: 1em;
border-radius: 15px;
border: 1px solid Silver;
color: black;
margin-bottom: 1.5em;
`;

export const CardTitle = styled.p`
    text-align: center;
    font-size: 1.5em;
    margin-bottom: .3em;
    color: #309E72;
    font-family: sassoon-sans-std-bold;
`;

export const ProfileView = styled.div`
    width: 100%;
    padding: .6em 1em;
`;

export const TasksHeader = styled.nav`
    background: #0B5278; 
    border-radius:15px 15px 0 0;
`;

export const Task = styled.div`
    padding: 0em;
    height: 90%;
`;

export const CardHeader = styled.div`
    background:#0B5278;
    border-radius: 15px 15px 0 0;
    padding-top: 1em;
    padding-bottom: 2.5em;
`;

export const GreenInput = styled.label`
    background: #309E72;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    margin: .5em 0;
    border: solid 1px #309E72;
    cursor:pointer;
    
    :active {
        background: #5B6EFF;
        border: solid 1px #5B6EFF;
    }
`;

export const BlueInput = styled.label`
    background: #5B6EFF;
    font-family: sassoon-sans-std-bold;
    font-size: 1em;
    color: white;
    border-radius: 30px;
    padding: 8px 18px;
    margin: .5em 0;
    border: solid 1px #5B6EFF;
    cursor:pointer;
    
    :active {
        background: #5B6EFF;
        border: solid 1px #5B6EFF;
    }
`;