import React, { Component } from "react";
import EmployerRegisterBox from "./employerRegisterBox";
import { LoginImg,LoginRender, TopContainer, BottomContainer, BottomLogo, RegisterRender} from "../Login/login-registerElements";

function EmployerRegister() {
    return (
        <RegisterRender>
            <div className="container-fluid" >
                <TopContainer className="row justify-content-center" style={{paddingTop: "5vh", paddingBottom: "7vh"}}>
                    <div className="col col-lg-12">
                        <LoginImg src={require('../../assets/images/TextLogo.png')}/>
                    </div>
                </TopContainer>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <EmployerRegisterBox/>
                    </div>
                </div>
                <BottomContainer className="container">
                    <div className="row justify-content-xs-center">
                            <div className="col-12 col-xs-12">
                                <picture>
                                    <source media="(max-width: 768px)" srcSet={require('../../assets/images/logos-responsive.png')}/>  
                                    <BottomLogo style={{marginTop: "10vh"}} src={require('../../assets/images/logos.png')}/>
                                </picture>
                            </div>
                            
                        </div>
                </BottomContainer>
            </div>
        </RegisterRender>
    );
};

export default EmployerRegister;