import React from 'react';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

import { SearchContainer, SearchTable, SearchInput, GreenButton, DropdownContainers, DarkBlueButton} from './jobElements';

function SearchBar() {
    return (
        <SearchContainer>
            <SearchTable className="container-fluid">
                <div className="row">
                    <div className="col-5">
                        <SearchInput type="text" className="form-control" placeholder="" aria-label="searchBar" aria-describedby="searchInput"/>
                    </div>
                    <div className="col-2">
                        <GreenButton className="btn btn-primary" type="button" >Buscar Empleos</GreenButton>
                    </div>
                    <div className="col-md-5">
                        <div className="btn-group">
                            <DropdownContainers className="dropdown">
                                <DarkBlueButton className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Fecha de Publicación <FaAngleDown/>
                                </DarkBlueButton>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><button className="btn btn-secondary dropdown-item">Últimas 24 horas</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item">Últimos 7 días</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item">Últimos 30 días</button></li>
                                </ul>
                            </DropdownContainers>

                            <DropdownContainers className="dropdown">
                                <DarkBlueButton className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Empresa <FaAngleDown/>
                                </DarkBlueButton>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><button className="btn btn-secondary dropdown-item">IBM</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item">Oracle</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item">Intel</button></li>
                                </ul>
                            </DropdownContainers>

                            <DropdownContainers className="dropdown">
                                <DarkBlueButton className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Salario <FaAngleDown/>
                                </DarkBlueButton>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><button className="btn btn-secondary dropdown-item">Menor a mayor</button></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><button className="btn btn-secondary dropdown-item">Mayor a menor</button></li>
                                </ul>
                            </DropdownContainers>
                        </div>
                    </div>
                </div>
            </SearchTable>
        </SearchContainer>
    );
};



export default SearchBar;