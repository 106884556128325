// ----------------------------------- Librarys -----------------------------------
import React, { useState, useEffect } from "react";
import { getStudentsActive } from "../../controllers/students";
//import EditOffer from "./editOffer"; // EditarEstudiante
import Capitalize from '../Student/capitalize';
// ------------------------------------ Styles ------------------------------------
import { ListItem, Card, Title, Description, IconContainer } from "./operatorElements";
import { MdDeleteOutline } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { Loading, OopsLoading } from "../loading";
import { getInstitutions } from "../../controllers/institutionsAndCareers";
import ViewStudentProfile from "../HHRR/viewStudentProfile";
import { deleteStudentById } from "../../controllers/students";
import { Toast } from "../alert";
import { deleteUserById } from "../../controllers/users";
// --------------------------------------------------------------------------------

const Swal = require('sweetalert2');

function StudentsBox() {

    const [isLoading, setLoading] = useState(true);
    const [allDataCharged, setAllDataCharged] = useState(false);
    const [state, setState] = useState({
        studentsList: [],
        institutions: []
    });

    const getAllData = async () => {
        const response = await getStudentsActive();
        const studentsData = await response.json();
        
        const institutionsData = await getInstitutions();

        setState({ ...state, studentsList: studentsData, institutions: institutionsData});

        setAllDataCharged(true);
    };
    
    const deleteButton = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleDeleteStudent = async (student) => {

        let studentName = Capitalize(student.givenNames)+" "+Capitalize(student.familyNames);

        Swal.fire({
            title: '¿Está seguro de que desea eliminar al estudiante '+ studentName +'?',
            text: "¡No se podrán revertir los cambios!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar'
            }).then(async (result) => {

                if (result.isConfirmed) {
                    const response = await deleteStudentById(student.id);
                    const response2 = await deleteUserById(student.userId);

                    if(response.ok && response2.ok){
                        Toast.fire(
                            '¡Eliminado!',
                            studentName + ' ha sido eliminado.',
                            'success'
                        )

                        getAllData();
                    }
                        
                    else
                        Toast.fire(
                            'Error',
                            studentName + ' no ha sido eliminado.',
                            'error'
                        )
                }
        })
    }

    useEffect(() => {
        if (!allDataCharged)
            getAllData();
        else
            setLoading(false);
    });

    const render = () => {
        if (isLoading) return <Loading />;

        else if (state.studentsList[0] === undefined) return <OopsLoading type={"estudiantes"} />;

        return (state.studentsList.map((student) => {
            return (
                <ListItem key={student.id}>
                    <Card className="card w-100">
                        <div className="card-body">
                            <div className="container-fluid" style={{ paddingInline: "0" }}>
                                <div className="row">
                                    <div className="col-10">
                                        <Title>{student.givenNames} {student.familyNames}</Title>
                                    </div>
                                    <div className="col-2" style={{ textAlign: "right", }}>
                                        <IconContainer>
                                            <FiEye size={24} cursor="pointer" data-bs-toggle="modal" data-bs-target={"#view-"+ student.id}/>
                                        </IconContainer>
                                        <IconContainer >
                                            <MdDeleteOutline size={30} cursor="pointer" onClick={() => handleDeleteStudent(student)}/>
                                        </IconContainer>
                                    </div>
                                </div>
                            </div>
                            <Description>{student.university}</Description>
                        </div>
                    </Card>

                    <div  class="modal fade" id={"view-"+ student.id} aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "50%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Perfil de estudiante</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <ViewStudentProfile Student={student} Institutions={state.institutions}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </ListItem>
            );
        }));
    };

    return render();
};

export default StudentsBox;