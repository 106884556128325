import React, {useState, useEffect} from 'react';
import {ListItem, JobCard, JobTitle, JobEmployer, JobTime,  GreenButton, GreenButtonRes, GreenButtonStatic} from './jobElements';
import Cookies from 'universal-cookie';
import { OopsLoading, WhiteLoading } from '../loading';
import styled from "styled-components";
import { getStudents } from '../../controllers/studentInfo';
import { applyOffer } from '../../controllers/applications';
import { getApplicationsByStudent, deleteApplication } from '../../controllers/applications';
import ViewOffer from '../HHRR/viewOffer';
import Swal from 'sweetalert2';
import { Toast } from '../alert';
import { getInstitutions } from '../../controllers/institutionsAndCareers';

const cookies = new Cookies();

function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const CancelButton = styled.button`
    background: #309E72;
    font-size: .8em;
    color: white;
    border-radius: 20px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;

    &:hover {
        background: #167E54;
        border: solid 1px #167E54;
    }
`;

const DeleteButton = styled.button`
    background: #309E72;
    font-size: .8em;
    color: white;
    border-radius: 20px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;

    &:hover {
        background: #CA3737;
        border: solid 1px #CA3737;
    }
`;

function MyApplications({Offers, Employers}) {

    const [applicationsLoaded, setApplicationsLoaded] = useState(false);
    
    const [state, setState] = useState({
        applications: [],
        institutions: [],
    });

    const getMyApplications = async() => {
        const applications = await getApplicationsByStudent(cookies.get("studentId"));
        const institutionData = await getInstitutions();

        setState({...state, applications : applications, institutions: institutionData});
        setApplicationsLoaded(true);
    };
    
    function handleDelete(application, offer) {
        Swal.fire({
            title: '¿Está seguro de que desea eliminar la postulación de la oferta '+ offer.title +'?',
            text: "¡No se podrán revertir los cambios!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar'
            }).then(async (result) => {

                if (result.isConfirmed) {
                    const response = await deleteApplication(application.id);

                    if(response.ok){
                        Toast.fire(
                            '¡Eliminado!',
                            'La postulación de la oferta ' + offer.title + ' ha sido eliminada.',
                            'success'
                        )

                        getMyApplications();
                    }
                        
                    else
                        Toast.fire(
                            'Error',
                            'La postulación de la oferta ' + offer.title + ' no ha sido eliminada.',
                            'error'
                        )
                }
        })
    }

    useEffect(() => {
        if (!applicationsLoaded)
            getMyApplications();

    });

    const render = () => {

    if (!applicationsLoaded) return <WhiteLoading/>;
    else if (state.applications[0] === undefined) return <OopsLoading type={"postulaciones"}/>;
    
    return (
        state.applications.map((application) => {
        
            const offerData = Offers.filter((offer) => offer.id === application.jobOfferId);
            const employerData = Employers.filter((employer) => employer.id === application.employerId);

            const skills = offerData[0].skills.split(',');
            const neuros = offerData[0].neurodiversities.split(',');

            return (
                <ListItem>
                    <JobCard class="card w-100">
                        <div class="card-body">

                            <JobTitle>{offerData[0].title}</JobTitle>
                            <JobEmployer>{employerData[0].name}</JobEmployer>                    
                            <div class="container" style={{textAlign:"center"}}>
                                <div class="row justify-content-center">
                                    <div class="col">
                                        <GreenButton data-bs-toggle="modal" data-bs-target={"#view-"+ offerData[0].id}>Oferta de Trabajo</GreenButton>
                                        <GreenButtonRes data-bs-toggle="modal" data-bs-target={"#viewRes-"+ offerData[0].id}>Oferta de Trabajo</GreenButtonRes>
                                    </div>
                                    <div class="col">
                                        <GreenButtonStatic onClick={() => handleDelete(application, offerData[0])}>Cancelar Postulación</GreenButtonStatic> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </JobCard>

                    <div  class="modal fade" id={"view-"+ offerData[0].id} aria-labelledby="Ver Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "70%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Oferta de trabajo</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <ViewOffer Offer={offerData[0]} Skills={skills} Neuro={neuros} Institutions={state.institutions}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  class="modal fade" id={"viewRes-"+ offerData[0].id} aria-labelledby="Ver Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "95%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Oferta de trabajo</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <ViewOffer Offer={offerData[0]} Skills={skills} Neuro={neuros} Institutions={state.institutions}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div  class="modal fade" id={"delete-"+ application.id} aria-labelledby="Eliminar Postulación" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1.5em"}}>
                                <div class="modal-body">
                                    <p style={{fontFamily: "sassoon-sans-std-bold"}}>¿Seguro que deseas borrar esta postulación?</p>
                                    <br/>
                                    <p style={{textAlign : "center"}}>{offerData[0].title}</p>
                                </div>
                                <div class="modal-footer" style={{background: "#0B5278"}}>
                                    <CancelButton type="button" class="btn" data-bs-dismiss="modal" >Cancelar</CancelButton>
                                    <DeleteButton type="button" class="btn" role="button" data-bs-dismiss="modal" onClick={() => handleDelete(application.id)}>Eliminar</DeleteButton>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </ListItem>

                
            );
        }
        )
    )
 };

 return render();
};

export default MyApplications;