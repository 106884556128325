//import React from "react";
import config from "../config/default.json";
import { accessToken } from "./login";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUrl = (path) => {
  return `${config["entender-api"].url}${path}`;
};

export const getJobOffers = async () => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/job-offers"), requestOptions);
  const jobOffers = await response.json();
  
  return jobOffers;
};

export const getJobOfferById = async () => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/job-offer/{id}"), requestOptions);
};

export const getJobOfferByEmployerId = async (employerId) => {

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/job-offers/findByEmployerId/"+employerId), requestOptions);
  const jobOffers = await response.json();

  return jobOffers;
};

export const getJobOffersByTitle = async (title) => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/job-offers/findByTitle/"+title), requestOptions);
  const jobOffers = await response.json();
  
  return jobOffers;
};

export const getJobOffersByDate = async (date) => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/job-offers/findByDate/"+date), requestOptions);
  const jobOffers = await response.json();
  
  return jobOffers;
};

export const getJobOffersByType = async (type) => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/job-offers/findByType/"+type), requestOptions);
  const jobOffers = await response.json();
  
  return jobOffers;
};

export const getJobOffersBySchedule = async (schedule) => {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    }),
  };

  const response = await fetch(getUrl("/job-offers/findBySchedule/"+schedule), requestOptions);
  const jobOffers = await response.json();
  
  return jobOffers;
};

export const deleteOffer = async (jobOfferId) => {

  const requestOptions = {
      method: 'DELETE',
      mode: "cors",
      headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("accessToken"),
      }),
  };

  const response = await fetch(getUrl("/job-offer/"+jobOfferId), requestOptions);

  return response;
}
