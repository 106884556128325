import React, {  useState, useEffect } from "react";
import Lorem from '../../assets/images/Lorem';
import { MdDeleteOutline } from "react-icons/md";
import styled from "styled-components";
import { FiEdit } from "react-icons/fi";
import {ListItem, JobCard, JobTitle, JobEmployer, JobSalary, JobTime, JobDescription, IconContainer, RegisterSelector, RegisterCard, SubmitButton, RegisterInput, OfferDescription, InputLabel} from './hhrrElements';
import { deleteOffer } from '../../controllers/jobOffers';
import { Loading, OopsLoading, WhiteLoading } from '../loading';
import { getJobOfferByEmployerId } from "../../controllers/jobOffers";

import Cookies from 'universal-cookie';
import OfferBox from "./offerBox";

import Swal from "sweetalert2";
import { Toast } from "../alert";

const cookies = new Cookies();

const CancelButton = styled.button`
    background: #309E72;
    font-size: .8em;
    color: white;
    border-radius: 20px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;

    &:hover {
        background: #167E54;
        border: solid 1px #167E54;
    }
`;

const DeleteButton = styled.button`
    background: #309E72;
    font-size: .8em;
    color: white;
    border-radius: 20px;
    padding: 8px 18px;
    border: solid 1px #309E72;
    font-family: sassoon-sans-std-bold;
    margin: 0 1em 0 0;

    &:hover {
        background: #CA3737;
        border: solid 1px #CA3737;
    }
`;

let myOffers = [];

//We need to see how to update offers without doing a cycle!

function MyOffers({SkillsList, NeurodiversitiesList, InstitutionsList}) {
    
    const [isLoading, setLoading] = useState(true);
    const [offersDataCharged, setOffersDataCharged] = useState(false);
    const [state, setState] = useState({
        offerList: [],
    });
    
    const getMyOffers = async () => {
        const offers = await getJobOfferByEmployerId(cookies.get("employerId"));

        setState({...state, offerList: offers});
        setOffersDataCharged(true);
    };

    function handleDelete(offer) {
        Swal.fire({
            title: '¿Está seguro de que desea eliminar la oferta de trabajo '+ offer.title +'?',
            text: "¡No se podrán revertir los cambios!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar'
            }).then(async (result) => {

                if (result.isConfirmed) {
                    const response = await deleteOffer(offer.id);

                    if(response.ok){
                        Toast.fire(
                            '¡Eliminado!',
                            'La oferta ' + offer.title + ' ha sido eliminado.',
                            'success'
                        )

                        getMyOffers();
                    }
                        
                    else
                        Toast.fire(
                            'Error',
                            'La oferta ' + offer.title + ' no ha sido eliminado.',
                            'error'
                        )
                }
        })
    }

    useEffect(() => {
        if(!offersDataCharged)       
            getMyOffers();

        if(offersDataCharged)
            setLoading(false);
    });

    let index = 0;
    
    const render = () => {
        if (isLoading) return <Loading/>;
        else if (state.offerList[0] === undefined) return <OopsLoading type={"ofertas de trabajo"}/>;

        return ( state.offerList.map((offer) => {

            let description = offer.description.substring(0,250);

            return(
                <ListItem  key={offer.id}>
                    <JobCard class="card w-100">
                        <div class="card-body">
                            <div class="container-fluid" style={{paddingInline:"0"}}>
                            <div class="row">
                                <div class="col-10">
                                    <JobTitle>{offer.title}</JobTitle>
                                </div>
                                <div class="col-2" style={{ textAlign:"right",}}>
                                    <IconContainer>
                                        <FiEdit data-bs-toggle="modal" data-bs-target={"#edit-"+ offer.id} size={24}/>
                                    </IconContainer>
                                    <IconContainer >
                                        <MdDeleteOutline size={30} onClick={() => handleDelete(offer)}/>
                                    </IconContainer>
                                </div>
                            </div>
                            </div>
                        
                            <JobEmployer>{cookies.get("employerName")}</JobEmployer>
                            <JobDescription>
                                {description}...<br/><br/>
                            </JobDescription>
                        </div>
                    </JobCard>
                    
                    {/* <div class="modal fade" id={"delete-"+ offer.id} aria-labelledby="Eliminar Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1.5em"}}>
                                <div class="modal-body">
                                    <p style={{fontFamily: "sassoon-sans-std-bold"}}>¿Seguro que deseas borrar esta oferta de trabajo?</p>
                                    <br/>
                                    <p style={{textAlign : "center"}}>{offer.title}</p>
                                </div>
                                <div class="modal-footer" style={{background: "#0B5278"}}>
                                    <CancelButton type="button" class="btn" data-bs-dismiss="modal" >Cancelar</CancelButton>
                                    <DeleteButton type="button" class="btn" role="button" data-bs-dismiss="modal" onClick={() => handleDelete(offer.id)}>Eliminar</DeleteButton>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div  class="modal fade" id={"edit-"+ offer.id} aria-labelledby="Eliminar Oferta" aria-hidden="true" style={{fontFamily: "sassoon-sans-std"}}>
                        <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "50%" }}>
                            <div class="modal-content" style={{background: "white", color: "black", fontSize: "1em",}}>
                                <div class="modal-header">
                                    <h5 class="modal-title" style={{marginLeft: "20px"}}><b>Modificar oferta de trabajo</b></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginRight: "10px"}}></button>
                                </div>
                                <div class="modal-body">
                                    <OfferBox Offer={offer} InstitutionsList={InstitutionsList} SkillsList={SkillsList} NeurodiversitiesList={NeurodiversitiesList}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </ListItem>

            );
        })
    );
};

    return render();
};



export default MyOffers;