import React from 'react';
import Lorem from '../../assets/images/Lorem';

import {ListItem, JobCard, JobTitle, JobEmployer, JobSalary, JobTime, JobDescription} from './jobElements';

function JobItem() {
    return (
        <ListItem >
            <JobCard className="card w-100" onClick={() => alert("Hello from here")}>
                <div className="card-body">
                    <JobTitle>Administrador de BD</JobTitle>
                    <JobEmployer>Oracle</JobEmployer>
                    <JobSalary>$0,000 al mes</JobSalary>
                    <JobDescription>
                        <Lorem/>
                    </JobDescription>
                    <JobTime>Hace 1 día</JobTime>
                </div>
            </JobCard>
        </ListItem>
    );
};



export default JobItem;