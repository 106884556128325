// ----------------------------------- Librarys -----------------------------------
import React, {  useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getUserById } from "../../controllers/users";
import { updateEmployer } from "../../controllers/employers";
// ------------------------------------ Styles ------------------------------------
import { RegisterInput, GreenButton, RegisterBoxRender, RegisterCard, InputLabel, InputLabelMiniBold, AboutMe } from "../Login/login-registerElements";
import { Toast } from "../alert";
import { Loading } from '../loading';
import e from "cors";
// --------------------------------------------------------------------------------

const cookies = new Cookies();

function EmployerRegisterBox() {
    const userId = cookies.get("userId");
    const employerId = cookies.get("employerId");
    const employerUserId = cookies.get("employerUserId");
    
    const [isLoading, setLoading] = useState(true);
    const [verifyPassword, setVerifyPassword] = useState(false);

    const [state, setState] = useState({
        password: "",
        repeatPassword: "",
    })
    
    const [employerData, setEmployerData] = useState({
        userId: userId,
        employerId: employerId,
        employerUserId: employerUserId,
        name: "",
        description: "",
        email: "",
        password: "",
        // image
    })
    
    const getEmployerData = async () => {
        const response = await getUserById(userId);
        const content = await response.json();
        setEmployerData({...employerData, email: content.username});
    };

    const verifyPasswords = async (event) => {
        let repeatPassword = event.target.value;
        const input = document.getElementById("repetPasswordInput");

        setState({...state, repeatPassword: repeatPassword});

        if(state.password == repeatPassword){
            input.setAttribute("style", "border: solid 1px #28a745;");
            
            setEmployerData({...employerData, password: state.password});
            setVerifyPassword(true);
        }
        else {
            input.setAttribute("style", "border: solid 1px #d33;");

            setVerifyPassword(false);
        }
    }

    let navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(verifyPassword)
        {
            const response = await updateEmployer(employerData);

            if (response.ok) {
                Toast.fire({
                    icon: "success",
                    title: "Registro exitoso!",
                });

                navigate("/",{ replace: true });
            } 
            else {
                Toast.fire({
                    icon: "error",
                    title: "Error", // ? Regresar otro mensaje
                });
            }
        } else {
            Toast.fire({
                icon: "error",
                title: "Contraseñas incorrectas", // ? Regresar otro mensaje
            });
        }
    }

    useEffect(() => {
        if(employerData.email === "") getEmployerData();
        else { setLoading(false); };
    });

    const render = () => {
        if (isLoading) return <Loading/>;

        return (
            <RegisterBoxRender className="container" >
                <RegisterCard className="card">
                    <div className="card-body">
                        <form class="was-validated" onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-12">
                                    <InputLabel className="form-label">Compañía</InputLabel>
                                    <br />
                                    <RegisterInput type="text" value={employerData.name} required
                                    onChange={(e) => setEmployerData({...employerData, name: e.target.value})} />
                                    <div class="invalid-feedback">*Campo requerido</div>
                                    <br />
                                </div>
                            </div>

                            <div className="col-12">
                                <br />
                                <InputLabel className="form-label">
                                    Contraseña
                                </InputLabel>
                                <br />
                                <RegisterInput
                                    required
                                    placeholder="Ingresa tu contraseña"
                                    type="password"
                                    value={state.password}
                                    onChange={(e) =>
                                        setState({ ...state, password: e.target.value })
                                    }
                                />
                                <div class="invalid-feedback">*Campo requerido</div>
                                <br />
                            </div>

                            <div className="col-12">
                                <br />
                                <InputLabel className="form-label">
                                    Repite la contraseña
                                </InputLabel>
                                <br />
                                <RegisterInput
                                    required
                                    id="repetPasswordInput"
                                    placeholder="Repite tu contraseña"
                                    type="password"
                                    value={state.repeatPassword}
                                    onChange={(e) => verifyPasswords(e) }
                                />
                                <div class="invalid-feedback">*Campo requerido</div>
                                <br />
                                <br />
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <InputLabel  className="form-label">Descripción</InputLabel>
                                    <InputLabelMiniBold  className="form-label">(Opcional)</InputLabelMiniBold>
                                    <br/>
                                    <AboutMe 
                                        maxLength={250} 
                                        value={employerData.description}
                                        onChange={(e) => setEmployerData({...employerData, description: e.target.value})}
                                    />
                                    <br/>
                                    <br/>
                                </div>
                            </div> 

                            <div className="row">
                                <InputLabel className="form-label">Aviso de Privacidad</InputLabel>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="validationFormCheck1" required 
                                        style={{float: "none", marginRight: "1em"}}/>
                                        <label class="form-check-label" for="invalidCheck">
                                        He leído y acepto el <a href="http://www.transparencia.udg.mx/aviso-confidencialidad" target="_blank">Aviso de privacidad</a>
                                    </label>
                                    <br />
                                    <br />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-12" style={{ textAlign: "center" }}>
                                    <GreenButton type="submit" className="btn btn-primary" >Registrarme</GreenButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </RegisterCard>
            </RegisterBoxRender>
        );
    }

    return render();
};

export default EmployerRegisterBox;