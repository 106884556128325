import React from 'react';
import styled from 'styled-components';
import Lorem from '../../assets/images/Lorem';
import Navbar from '../studentNavbar';
import {ListTest, JobRender, ButtonNav, DivContainer, ProfileImg, ProfileImgContainer, ProfileImgBorder, UsernameLabel, StudentData, StudentDataCard, CardTitle,ProfileView,TasksHeader,Task,CardHeader} from './jobElements';
import JobItem from './jobItem';
import SearchBar from './searchBar';

function StudentView() {
    return (
        <div >
        <Navbar/>
        <ProfileView>
            <SearchBar/>
                <div className="row justify-content-center" style={{height: "92vh"}}>
                    <div className="col-md-5" style={{height: '100%'}}>
                        <JobRender>
                            <DivContainer className="card w-100">
                                <CardHeader className="card-header">
                                    
                                    <div className="row justify-content-center">
                                        <div className="col-md-12">
                                            <ProfileImgBorder>
                                                <ProfileImgContainer>
                                                    <ProfileImg src={require('../../assets/images/user.png')}/>
                                                </ProfileImgContainer>
                                            </ProfileImgBorder>
                                        </div>

                                        <div className="row justify-content-center">
                                            <UsernameLabel>
                                                Nombre de Usuario
                                            </UsernameLabel>
                                        </div>

                                    </div>
                                </CardHeader>

                                    <StudentData>
                                        <StudentDataCard className="card">
                                            <CardTitle className="card-title">Datos Personales</CardTitle>
                                            <label>Universidad: Universidad de Guadalajara</label>
                                            <br/>
                                            <label>Carrera: Ingeniería Informática</label>
                                            <br/>
                                            <label>Edad: 23 años</label>
                                            <br/>
                                            <label>Actualidad: Cursando 10 semestre</label>
                                        </StudentDataCard>

                                        <StudentDataCard className="card">
                                            <CardTitle className="card-title">Habilidades</CardTitle>
                                            <Lorem/>
                                        </StudentDataCard>

                                        <StudentDataCard className="card">
                                            <CardTitle className="card-title">Contacto</CardTitle>
                                            <label>Teléfono: +52 3312986013</label>
                                            <br/>
                                            <label>Correo: oscar.pperegrina@alumnos.udg.mx</label>
                                        </StudentDataCard>

                                    </StudentData>

                            </DivContainer>
                        </JobRender>
                    </div>
                    <div className="col-md-7" style={{height: "100%"}}>
                        <JobRender>
                      
                            <DivContainer className="card w-100">

                                <TasksHeader>
                                    
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{paddingTop: "1em", paddingInline:"1em", borderBottom: "0px"}}>
                                        <ButtonNav className="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab">Postulaciones</ButtonNav>
                                        <ButtonNav className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab">Guardados</ButtonNav>
                                    </div>

                                </TasksHeader>

                                <Task className="card-body">
                                    <div className="tab-content" id="nav-tabContent" style={{height: "100%"}}>

                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" style={{height: "100%"}}>

                                                <ListTest>
                                                    <JobItem/>
                                                    <JobItem/>
                                                    <JobItem/>
                                                </ListTest>

                                            </div>
                                      
                                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" style={{height: "100%"}}>
                                                <ListTest>
                                                    <JobItem/>
                                                    <JobItem/>
                                                    <JobItem/>
                                                </ListTest>
                                            </div>
                                    </div>
                                </Task>
                            </DivContainer>

                        </JobRender>
                    </div>
                    
                </div>
                </ProfileView>
        </div>
    );
};



export default StudentView;